export default {
  init() {
    this.placeSideShare();
  },
  placeSideShare() {
    const shareEl = $('.side-share');
    const anchorEl = $('.main').children();

    if (shareEl.length < 1) {
      return;
    }

    let topPos = 0;
    if ($('body').hasClass('template-home')) {
      topPos = $(window).height() / 2 - shareEl.height() / 2;
    } else {
      topPos = anchorEl.offset().top;
    }

    shareEl.css('top', topPos + 'px');
  },
};
