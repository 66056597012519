import Swiper, { Navigation, Thumbs, Keyboard } from 'swiper';

Swiper.use([Navigation, Thumbs]);

export default {
  gallerySwiper: null,
  thumbsSwiper: null,

  init() {
    this.events();
  },

  events() {
    $('body').on('click', '.lightbox__close', () => {
      this.close();
    });
    $(document).on('keyup', (e) => {
      if (e.key == 'Escape') {
        this.close();
      }
    });

    // Open lightbox if image in url
    const params = new URLSearchParams(window.location.search.substring(1));
    let test = params.get('image');
    if (test) {
      const images = $('.post-gallery').data('ids');
      this.loadImages(images, test - 1);
    }

    // Open lightbox
    $('.post-gallery .post-gallery__main').on('click', (e) => {
      const galleryEL = $(e.currentTarget).closest('.post-gallery');

      if (galleryEL.hasClass('post-gallery--video')) {
        const videoId = galleryEL.data('yt');
        this.loadVideo(videoId);
      } else {
        const url =
          window.location.origin + window.location.pathname + '?image=1';
        history.pushState(1, null, url);

        const images = galleryEL.data('ids');
        this.loadImages(images);
      }
    });
  },

  open(className) {
    $('html').addClass('no-scroll');
    $('#lightbox').addClass('open');
    $('#lightbox').addClass('lightbox--' + className);
  },

  close() {
    const lightboxEl = $('#lightbox');

    lightboxEl.removeClass('open');
    $('html').removeClass('no-scroll');

    if (this.thumbsSwiper) {
      this.thumbsSwiper.destroy();
    }
    if (this.gallerySwiper) {
      this.gallerySwiper.destroy();
    }

    lightboxEl.find('.lightbox-gallery .swiper-wrapper').html('');
    lightboxEl.find('.lightbox-thumbs .swiper-wrapper').html('');

    let url = window.location.origin + window.location.pathname;
    history.pushState(null, null, url);
  },

  loadVideo(videoId) {
    if (!videoId) {
      return;
    }

    this.open('video');

    const videoUrl = 'https://www.youtube-nocookie.com/embed/' + videoId;
    const html = `<iframe width="560" height="315" src="${videoUrl}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

    $('#lightbox .lightbox-gallery .swiper-wrapper').html(html);
  },

  loadImages(images, index = 0) {
    const lightboxEl = $('#lightbox');

    this.open('gallery');

    $.ajax({
      // eslint-disable-next-line
      url: Sage.ajaxurl,
      type: 'POST',
      data: {
        action: 'cab_load_lightbox_images',
        images,
      },
    }).done((response) => {
      if (response.data) {
        let galleryHtml = '';
        let thumbsHtml = '';

        response.data.forEach((image) => {
          galleryHtml += `
          <div class="swiper-slide">
            <div class="lightbox-gallery__slide">
              <img src="${image.gallery.src}" width="${image.gallery.width}" height="${image.gallery.height}" alt="${image.alt}">
              <p class="lightbox-gallery__slide-caption">${image.caption}</p>
            </div>
          </div>
          `;

          thumbsHtml += `
          <div class="swiper-slide">
            <div class="lightbox-thumbs__slide">
              <img src="${image.thumb.src}" width="${image.thumb.width}" height="${image.thumb.height}" alt="${image.alt}">
            </div>
          </div>
          `;
        });

        lightboxEl.find('.lightbox-gallery .swiper-wrapper').html(galleryHtml);
        lightboxEl.find('.lightbox-thumbs .swiper-wrapper').html(thumbsHtml);

        const galleryEl = lightboxEl.find('.lightbox-gallery');
        const thumbsEl = lightboxEl.find('.lightbox-thumbs');
        this.initImageSwiper(galleryEl, thumbsEl, index);
      }
    });
  },

  initImageSwiper(galleryEl, thumbsEl, index = 0) {
    // Create thumbs
    this.thumbsSwiper = new Swiper(thumbsEl[0], {
      initialSlide: index,
      slidesPerView: 2,
      watchSlidesProgress: true,
      breakpoints: {
        600: {
          slidesPerView: 5,
        },
        900: {
          direction: 'vertical',
          slidesPerView: 'auto',
        },
      },
    });

    // Create gallery
    this.gallerySwiper = new Swiper(galleryEl[0], {
      initialSlide: index,
      modules: [Keyboard],
      keyboard: {
        enabled: true,
      },
      navigation: {
        prevEl: $('.lightbox__gallery').find('.swiper-button--prev')[0],
        nextEl: $('.lightbox__gallery').find('.swiper-button--next')[0],
      },
      thumbs: {
        swiper: this.thumbsSwiper,
      },
    });

    // Sync swipers
    this.gallerySwiper.params.control = this.thumbsSwiper;
    this.thumbsSwiper.params.control = this.gallerySwiper;

    // Update url
    this.gallerySwiper.on('slideChange', () => {
      const image = this.gallerySwiper.realIndex + 1;
      const url =
        window.location.origin + window.location.pathname + '?image=' + image;
      history.pushState(image, null, url);
    });
  },
};
