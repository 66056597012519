import { createStore } from 'redux';
const orderFavourites = {
  initialState: null,
  store: null,
  init() {
    this.loadItems();
    this.events();
  },

  checkPrint() {
    const queryVars = new URLSearchParams(location.search);
    const isPrint = queryVars.get('print');
    return isPrint;
  },

  initStore() {
    // Create redux store
    this.store = createStore(this.reducer);

    window.dispatch = this.store.dispatch;

    this.store.subscribe(() => {
      // this function will be called when state changes
      this.updateHtml();
    });
  },

  reducer(state = orderFavourites.initialState, action) {
    // Handle actions
    switch (action.type) {
      case 'moveUp':
        return orderFavourites.orderItems(state, action.itemID, -1);

      case 'moveDown':
        return orderFavourites.orderItems(state, action.itemID, 1);

      default:
        return state;
    }
  },

  orderItems(state, itemID, movement) {
    // Order html items
    const index = state.findIndex((obj) => {
      return obj.id == itemID;
    });

    const updatedArr = [...state];

    const tmp = updatedArr[index];
    const swapItem = updatedArr[index + movement];

    swapItem.num = tmp.num;
    tmp.num = swapItem.num + movement;

    updatedArr[index] = swapItem;
    updatedArr[index + movement] = tmp;

    return updatedArr;
  },

  events() {
    $(document).on('click', '.btn-wrap--up', (e) => {
      const itemID = $(e.currentTarget)
        .closest('.favourites-item')
        .attr('data-id');

      window.dispatch({ type: 'moveUp', itemID: itemID });
    });

    $(document).on('click', '.btn-wrap--down', (e) => {
      const itemID = $(e.currentTarget)
        .closest('.favourites-item')
        .attr('data-id');

      window.dispatch({ type: 'moveDown', itemID: itemID });
    });

    $('.btn-accept').on('click', () => {
      const newOrder = [];

      $('.favourites-item').each((_id, item) => {
        newOrder.push($(item).attr('data-id'));
      });

      localStorage.setItem('favourites', JSON.stringify(newOrder));
      location = $('.btn-cancel').attr('href');
    });
  },

  loadItems() {
    const Sage = window.Sage;

    const favs = JSON.parse(localStorage.getItem('favourites'));

    const data = {
      action: 'cab_load_order_favourites',
      posts: favs ? favs.join(',') : null,
    };

    $.ajax({
      type: 'post',
      url: Sage.ajaxurl,
      data: data,
    }).done((res) => {
      if (res.success) {
        this.initialState = res.data;
        this.initStore();
        $('.favourites-list').html(this.appendHtml(res.data));
        $('.order-actions').removeClass('hidden');

        const isPrint = this.checkPrint();

        if (isPrint) {
          window.print();
        }
      } else {
        $('.favourites-list').html(res.data.message);
      }
    });
  },

  appendHtml(items) {
    let htmlContent = '';

    items.forEach((item) => {
      let itemMetaHtml = '';

      if (item.info && item.info.location) {
        let location = item.info.location;
        if (typeof item.info.location == 'object') {
          location = location.address;
        }

        itemMetaHtml += `<div class="col">
          <div class="favourites-item__meta-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="13.001" height="14.864" viewBox="0 0 13.001 14.864"><path d="M5.736,14.711a1.983,1.983,0,0,1-.648-.433L1.9,11.1a6.5,6.5,0,1,1,9.193,0L7.915,14.279a2,2,0,0,1-2.179.433ZM3.445,1.927a5.5,5.5,0,0,0-.833,8.462l3.182,3.182a.989.989,0,0,0,.325.217,1,1,0,0,0,.764,0,.977.977,0,0,0,.324-.217l3.182-3.182A5.5,5.5,0,0,0,3.445,1.927ZM4.556,8.445A2.751,2.751,0,1,1,6.5,9.25,2.735,2.735,0,0,1,4.556,8.445Zm.707-3.182A1.751,1.751,0,1,0,6.5,4.75,1.739,1.739,0,0,0,5.263,5.262Z" transform="translate(0)"/></svg>
            ${location}
          </div>
        </div>`;
      }

      if (item.info && item.info.website) {
        itemMetaHtml += `<div class="col">
          <div class="favourites-item__meta-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="14.5" viewBox="0 0 14.5 14.5"><path d="M4.476,13.948A7.251,7.251,0,1,1,7.25,14.5,7.219,7.219,0,0,1,4.476,13.948ZM7.25,13.5A6.212,6.212,0,0,0,9,13.25V11.5a2,2,0,0,1,2-2H13.08a6.269,6.269,0,0,0-.1-4.75H12.5a1,1,0,0,0-1,1,2,2,0,0,1-4,0,1,1,0,0,0-1-1H6.125A2.375,2.375,0,0,1,3.75,2.375V2.069a6.317,6.317,0,0,0-.92.761A6.206,6.206,0,0,0,1.124,6h.934a2,2,0,0,1,2,2v.75a1,1,0,0,0,1,1,2,2,0,0,1,2,2V13.5ZM1,7.25a6.255,6.255,0,0,0,5.059,6.136V11.75a1,1,0,0,0-1-1,2,2,0,0,1-2-2V8a1,1,0,0,0-1-1H1Q1,7.125,1,7.25Zm9.293,3.542A.994.994,0,0,0,10,11.5v1.362A6.258,6.258,0,0,0,12.589,10.5H11A.99.99,0,0,0,10.293,10.793ZM4.75,2.375A1.375,1.375,0,0,0,6.125,3.75H6.5a2,2,0,0,1,2,2,1,1,0,0,0,2,0,2,2,0,0,1,1.931-2,6.316,6.316,0,0,0-.762-.921A6.264,6.264,0,0,0,4.75,1.518Z"/></svg>
            ${item.info.website}
          </div>
        </div>`;
      }

      if (item.info && item.info.telephone) {
        itemMetaHtml += `<div class="col">
          <div class="favourites-item__meta-item">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.5" height="14.5" viewBox="0 0 14.5 14.5"><path d="M2.75.75H5.21A1.25,1.25,0,0,1,6.4,1.6l1.123,3.37a1.25,1.25,0,0,1-.627,1.513l-1.25.626a7.776,7.776,0,0,0,3.245,3.245l.626-1.25a1.25,1.25,0,0,1,1.512-.627L14.4,9.6a1.25,1.25,0,0,1,.855,1.186V13.25a2,2,0,0,1-2,2H12.5a11.673,11.673,0,0,1-8.308-3.442A11.673,11.673,0,0,1,.75,3.5V2.75a2,2,0,0,1,2-2ZM9.112,11.525a.5.5,0,0,1-.206-.044A8.777,8.777,0,0,1,4.52,7.094a.5.5,0,0,1,.232-.653l1.693-.848a.25.25,0,0,0,.126-.3L5.447,1.921A.25.25,0,0,0,5.21,1.75H2.75a1,1,0,0,0-1,1V3.5A10.762,10.762,0,0,0,12.5,14.25h.75a1,1,0,0,0,1-1V10.791a.25.25,0,0,0-.171-.237L10.709,9.43a.25.25,0,0,0-.3.126l-.848,1.693A.5.5,0,0,1,9.112,11.525Z" transform="translate(-0.75 -0.75)"/></svg>
            ${item.info.telephone}
          </div>
        </div>`;
      }
      let ventiHtml = '';
      if (item.venti) {
        ventiHtml = `<div class="favourites-item__header-item favourite-venti">
          <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 16.5C0 7.3873 7.3873 0 16.5 0C25.6127 0 33 7.3873 33 16.5C33 25.6127 25.6127 33 16.5 33C7.3873 33 0 25.6127 0 16.5Z" fill="#FFDF3D"></path>
            <path d="M0 16.5C0 7.3873 7.3873 0 16.5 0C25.6127 0 33 7.3873 33 16.5C33 25.6127 25.6127 33 16.5 33C7.3873 33 0 25.6127 0 16.5Z" fill="#FECA00"></path>
            <path d="M21.3872 9.16669L16.4983 23.8334L11.6094 9.16669" stroke="#17110D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M7.94531 14.0555H25.0564" stroke="#17110D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M10.3906 17.7222H22.6128" stroke="#17110D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
          </svg>
        </div>`;
      }

      htmlContent += `<div class="favourites-item" data-id="${
        item.id
      }" data-order="${item.num}">
        <div class="favourites-item__number">
          ${item.num}
        </div>
        <div class="favourites-item__content">
          <div class="favourites-item__buttons">
            <div class="btn-wrap btn-wrap--up">
              <button class="btn btn-up"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="#0b0e16" viewBox="0 0 9 9"><path d="M4.146,8.853a.5.5,0,0,1,0-.707L7.293,5H.5a.5.5,0,1,1,0-1H7.293L4.146.853A.5.5,0,0,1,4.854.147L8.845,4.138a.5.5,0,0,1,0,.725L4.854,8.853a.5.5,0,0,1-.707,0Z" transform="translate(0 0)"></path></svg></button>
            </div>
            <div class="btn-wrap btn-wrap--down">
              <button class="btn btn-down"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="#0b0e16" viewBox="0 0 9 9"><path d="M4.146,8.853a.5.5,0,0,1,0-.707L7.293,5H.5a.5.5,0,1,1,0-1H7.293L4.146.853A.5.5,0,0,1,4.854.147L8.845,4.138a.5.5,0,0,1,0,.725L4.854,8.853a.5.5,0,0,1-.707,0Z" transform="translate(0 0)"></path></svg></button>
            </div>
          </div>
          <div class="favourites-item__header">
            ${
              item.category
                ? `<div class="favourites-item__header-item favourite-tag favourite-tag--white">${item.category}</div>`
                : ''
            }
            ${
              item.tag
                ? `<div class="favourites-item__header-item favourite-tag favourite-tag">${item.tag}</div>`
                : ''
            }
            ${ventiHtml}
            <div class="favourites-item__header-item favourite-heart">
              <svg xmlns="http://www.w3.org/2000/svg" width="23.994" height="20.703" viewBox="0 0 23.994 20.703"><g transform="translate(-681 -466)"><path d="M22.133,2.367a6.376,6.376,0,0,0-9.016,0L12,3.484,10.883,2.367a6.376,6.376,0,0,0-9.016,0h0a6.375,6.375,0,0,0,0,9.016l9.6,9.6a.748.748,0,0,0,1.06,0l9.6-9.6a6.376,6.376,0,0,0,0-9.016Zm-1.061,7.955L12,19.394,2.928,10.322A4.875,4.875,0,1,1,9.822,3.428L11.47,5.075a.749.749,0,0,0,1.06,0l1.648-1.647a4.875,4.875,0,1,1,6.894,6.894Z" transform="translate(681 465.5)" fill="#feca00"></path><path d="M2.928,3.428a4.873,4.873,0,0,0,0,6.894L12,19.394l9.072-9.072a4.875,4.875,0,1,0-6.894-6.894L12.53,5.075a.749.749,0,0,1-1.06,0L9.822,3.428a4.875,4.875,0,0,0-6.894,0Z" transform="translate(681 465.5)" fill="#fff" opacity="0"></path></g></svg>
            </div>
          </div>
          <h3 class="h3 favourites-item__title"><a href="${item.url}">${
        item.title
      }</a></h3>
          <div class="favourites-item__meta">
            <div class="flexbox v-c">
              <div class="col">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1033_26193)">
                  <path d="M15 0C18.9782 0 22.7936 1.58035 25.6066 4.3934C28.4197 7.20644 30 11.0218 30 15C30 18.9782 28.4197 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30C11.0218 30 7.20646 28.4196 4.39342 25.6066C1.58037 22.7936 0 18.9782 0 15C0 11.0218 1.58037 7.20644 4.39342 4.3934C7.20646 1.58035 11.0218 0 15 0Z" fill="#FEE6A7"/>
                  <path d="M8.03613 15.7607L19.8218 10.1786L14.229 21.9643L12.9969 17.0036L8.03613 15.7607Z" stroke="#0B0E16" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_1033_26193">
                  <rect width="30" height="30" fill="white"/>
                  </clipPath>
                  </defs>
                </svg>
              </div>
              ${itemMetaHtml}
              </div>
            </div>
          </div>
        </div>
      </div>`;
    });

    return htmlContent;
  },

  updateHtml() {
    // Insert new ordered items into DOM
    const state = this.store.getState();
    const hmtlArray = [];

    state.forEach((item) => {
      const contentString = `
		<div class="favourites-item" data-id="${item.id}" data-order="${item.num}">
			<div class="favourites-item__number">
				${item.num}
			</div>
			<div class="favourites-item__content">
				<div class="favourites-item__buttons">
					<div class="btn-wrap btn-wrap--up">
						<button class="btn btn-up"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="#0b0e16" viewBox="0 0 9 9"><path d="M4.146,8.853a.5.5,0,0,1,0-.707L7.293,5H.5a.5.5,0,1,1,0-1H7.293L4.146.853A.5.5,0,0,1,4.854.147L8.845,4.138a.5.5,0,0,1,0,.725L4.854,8.853a.5.5,0,0,1-.707,0Z" transform="translate(0 0)"></path></svg></button>
					</div>
					<div class="btn-wrap btn-wrap--down">
						<button class="btn btn-down"><svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="#0b0e16" viewBox="0 0 9 9"><path d="M4.146,8.853a.5.5,0,0,1,0-.707L7.293,5H.5a.5.5,0,1,1,0-1H7.293L4.146.853A.5.5,0,0,1,4.854.147L8.845,4.138a.5.5,0,0,1,0,.725L4.854,8.853a.5.5,0,0,1-.707,0Z" transform="translate(0 0)"></path></svg></button>
					</div>
				</div>
				<div class="favourites-item__header">
					${
            item.category
              ? `<div class="favourites-item__header-item favourite-tag favourite-tag--white">${item.category}</div>`
              : ''
          }
					${
            item.tag
              ? `<div class="favourites-item__header-item favourite-tag favourite-tag">${item.tag}</div>`
              : ''
          }
						<div class="favourites-item__header-item favourite-venti">
							<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M0 16.5C0 7.3873 7.3873 0 16.5 0C25.6127 0 33 7.3873 33 16.5C33 25.6127 25.6127 33 16.5 33C7.3873 33 0 25.6127 0 16.5Z" fill="#FFDF3D"></path>
								<path d="M0 16.5C0 7.3873 7.3873 0 16.5 0C25.6127 0 33 7.3873 33 16.5C33 25.6127 25.6127 33 16.5 33C7.3873 33 0 25.6127 0 16.5Z" fill="#FECA00"></path>
								<path d="M21.3872 9.16669L16.4983 23.8334L11.6094 9.16669" stroke="#17110D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
								<path d="M7.94531 14.0555H25.0564" stroke="#17110D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
								<path d="M10.3906 17.7222H22.6128" stroke="#17110D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
							</svg>
						</div>
						<div class="favourites-item__header-item favourite-heart">
							<svg xmlns="http://www.w3.org/2000/svg" width="23.994" height="20.703" viewBox="0 0 23.994 20.703"><g transform="translate(-681 -466)"><path d="M22.133,2.367a6.376,6.376,0,0,0-9.016,0L12,3.484,10.883,2.367a6.376,6.376,0,0,0-9.016,0h0a6.375,6.375,0,0,0,0,9.016l9.6,9.6a.748.748,0,0,0,1.06,0l9.6-9.6a6.376,6.376,0,0,0,0-9.016Zm-1.061,7.955L12,19.394,2.928,10.322A4.875,4.875,0,1,1,9.822,3.428L11.47,5.075a.749.749,0,0,0,1.06,0l1.648-1.647a4.875,4.875,0,1,1,6.894,6.894Z" transform="translate(681 465.5)" fill="#feca00"></path><path d="M2.928,3.428a4.873,4.873,0,0,0,0,6.894L12,19.394l9.072-9.072a4.875,4.875,0,1,0-6.894-6.894L12.53,5.075a.749.749,0,0,1-1.06,0L9.822,3.428a4.875,4.875,0,0,0-6.894,0Z" transform="translate(681 465.5)" fill="#fff" opacity="0"></path></g></svg>
						</div>
					</div>
					<h3 class="h3 favourites-item__title">${item.title}</h3>
				</div>
			</div>
		</div>
	  `;
      hmtlArray.push(contentString);
    });

    $('.favourites-list').html(hmtlArray.join(''));
  },
};

export default orderFavourites;
