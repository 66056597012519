import LazyLoad from 'vanilla-lazyload';

const HeroVisual = {
  heartbeat: null,
  offset: 0,
  loaded: false,
  playing: false,
  paused: false,
  mouseIsOver: false,

  imgWidth: 13000,
  imgHeight: 3000,
  startingPoint: 34,

  init() {
    if (window.outerWidth < 1100) {
      this.startingPoint = 56;
    }

    // Clone slide for infinite loop
    $('.hero-visual-block:first').clone().appendTo('.hero-visual__panel');

    const heroLazyLoad = new LazyLoad({
      elements_selector: '.hero-visual-block-image',
      callback_finish: () => {
        HeroVisual.loaded = true;

        // Fix starting point
        const width = $('.hero-visual-block').width();
        let offset = (this.startingPoint / 100) * width;

        this.offset = offset;

        HeroVisual.playAnimation();
      },
    });
    heroLazyLoad.loadAll();

    // parallax
    HeroVisual.initParallax();
    $(window).on('resize', HeroVisual.initParallax);
    // hotspots
    HeroVisual.hotspot();
    // Resp
    HeroVisual.responsive();
    $(window).on('resize', HeroVisual.responsive);
    // events
    HeroVisual.events();
  },

  isMobile() {
    return /Mobi/i.test(window.navigator.userAgent);
  },

  /**
   * Responsive
   */
  responsive() {
    // calculate new width based on window height
    const newWidth =
      ($('.hero-visual').outerHeight() * HeroVisual.imgWidth) /
      HeroVisual.imgHeight;

    $('.hero-visual-block').css({
      width: newWidth,
    });

    $(document).trigger('renderHotspots');
  },

  // Hotspot events.
  hotspot() {
    $(document).on('renderHotspots', () => {
      const block = $('.hero-visual-block');

      $('.hotspot').each((_i, item) => {
        const hotspot = $(item);

        // calculate new positon based on block height
        const newTop =
          (hotspot.data('top') / HeroVisual.imgHeight) * block.height();
        const newLeft =
          (hotspot.data('left') / HeroVisual.imgWidth) * block.width();

        hotspot.css({
          top: newTop,
          left: newLeft,
        });
      });
    });

    // Open hotspot.
    $(document).on('click', '.hotspot__toggle', (e) => {
      // Stop animation.
      HeroVisual.stopAnimation();
      HeroVisual.paused = true;

      // Handle classes
      HeroVisual.closeHotspot();

      const btn = $(e.currentTarget);
      const hotspot = btn.closest('.hotspot');

      hotspot.addClass('hotspot--open');

      // Fix outside box.
      HeroVisual.fixHotspotPos(hotspot.children('.hotspot__wrap'));
    });

    // Close hotspot on click outside.
    $(document).on('mouseup', (e) => {
      const container = $('.hotspot');

      if (
        container.hasClass('hotspot--open') &&
        !container.is(e.target) &&
        container.has(e.target).length === 0
      ) {
        // Start animation
        HeroVisual.paused = false;
        setTimeout(() => {
          HeroVisual.playAnimation();
        }, 50);
        // Close hotspots
        HeroVisual.closeHotspot();
      }
    });
  },

  fixHotspotPos(hotspotWrap) {
    const wrap = $('.hero-visual');

    // Fix bottom.
    const wrapBottom = wrap.offset().top + wrap.height();
    const hotspotBottom = hotspotWrap.offset().top + hotspotWrap.height();

    if (wrapBottom < hotspotBottom) {
      let offset = hotspotBottom - wrapBottom;

      hotspotWrap.css({
        marginTop: -offset,
      });
    }

    // Fix left.
    const wrapLeft = wrap.offset().left;
    const hotspotLeft = hotspotWrap.offset().left;

    if (wrapLeft > hotspotLeft) {
      let offset = -hotspotLeft;

      hotspotWrap.css({
        marginLeft: offset,
      });
    }

    // Fix right.
    const wrapRight = wrap.offset().left + wrap.width();
    const hotspotRight = hotspotWrap.offset().left + hotspotWrap.width();

    if (wrapRight < hotspotRight) {
      let offset = -(hotspotRight - wrapRight);

      hotspotWrap.css({
        marginLeft: offset,
      });
    }
  },

  closeHotspot() {
    $('.hotspot').removeClass('hotspot--open');
    $('.hotspot__wrap').css({
      marginTop: 0,
      marginLeft: 0,
    });
  },

  /**
   * Init parallax effect.
   */
  initParallax() {
    // Check if is mobile device.
    if (!HeroVisual.isMobile()) {
      $('body').on('mousemove', '.hero-visual', HeroVisual.parallaxEffect);
      $(window).off('scroll', HeroVisual.parallaxEffectMobile);
    } else {
      $(window).on('scroll', HeroVisual.parallaxEffectMobile);
      $('body').off('mousemove', '.hero-visual', HeroVisual.parallaxEffect);
    }
  },

  parallaxEffect(e) {
    const layers = {
      1: 10,
      2: 20,
      3: 50,
    };

    // Layers.
    HeroVisual.parallax($('.hero-visual-block__image--1'), layers[1], e);
    HeroVisual.parallax($('.hero-visual-block__image--2'), layers[2], e);
    HeroVisual.parallax($('.hero-visual-block__image--3'), layers[3], e);

    // Hotspots.
    $('.hotspot').each((_i, item) => {
      const hotspot = $(item);
      const layer = hotspot.data('layer');

      HeroVisual.parallax(hotspot, layers[layer], e);
    });
  },

  parallaxEffectMobile() {
    const layers = {
      1: 25,
      2: 15,
      3: 5,
    };

    // Layers.
    HeroVisual.mobileParallax($('.hero-visual-block__image--1'), layers[1]);
    HeroVisual.mobileParallax($('.hero-visual-block__image--2'), layers[2]);
    HeroVisual.mobileParallax($('.hero-visual-block__image--3'), layers[3]);

    // Hotspots.
    $('.hotspot').each((_i, item) => {
      const hotspot = $(item);
      const layer = hotspot.data('layer');

      HeroVisual.mobileParallax(hotspot, layers[layer]);
    });
  },

  /**
   * Parallax effect.
   */
  mobileParallax(element, intensity) {
    const scrollTop = $(window).scrollTop();

    let y = (scrollTop / 10) * (intensity * 0.1);

    if (y < 0) {
      y = 0;
    }

    element.css({
      transform: `translateY(${y}px)`,
    });
  },

  /**
   * Parallax effect.
   */
  parallax(element, intensity, e) {
    let x = (-e.pageX / 100) * (intensity * 0.1);

    element.css({
      transform: `translate3d(${x}px, 0, 0)`,
    });
  },

  /**
   * Events for animation
   */
  events() {
    $('.hero-visual')
      .on('mouseenter', () => {
        if (!HeroVisual.isMobile()) {
          HeroVisual.mouseIsOver = true;
          HeroVisual.stopAnimation();
        }
      })
      .on('mouseleave', () => {
        if (!HeroVisual.isMobile()) {
          HeroVisual.mouseIsOver = false;
          setTimeout(() => {
            HeroVisual.playAnimation();
          }, 50);
        }
      });
  },

  /**
   * Init animation.
   */
  playAnimation() {
    if (!HeroVisual.loaded) {
      return;
    }

    if (HeroVisual.heartbeat || HeroVisual.mouseIsOver || HeroVisual.paused) {
      return;
    }

    if (HeroVisual.endAnimation) {
      clearTimeout(HeroVisual.endAnimation);
    }

    $('.hero-visual__panel').css({
      transform: `translate3d(-${HeroVisual.offset}px, 0, 0)`,
    });

    // Remove loader
    $('.hero-visual__loader').addClass('hidden');

    // Init animation.
    HeroVisual.preStartAnimation = setTimeout(() => {
      HeroVisual.playing = true;

      HeroVisual.calcOffset(30);

      $('.hero-visual__panel').css({
        transition: 'all 0.5s linear',
        transform: `translate3d(-${HeroVisual.offset}px, 0, 0)`,
      });
    }, 500);

    // Start animation.
    HeroVisual.startAnimation = setTimeout(() => {
      $('.hero-visual__panel').css({
        transition: 'unset',
      });
      HeroVisual.mainLoop();
    }, 1000);
  },

  /**
   * Stop animation
   */
  stopAnimation() {
    // Clear init.
    if (HeroVisual.preStartAnimation) {
      clearTimeout(HeroVisual.preStartAnimation);
    }
    if (HeroVisual.startAnimation) {
      clearTimeout(HeroVisual.startAnimation);
    }

    // End animation.
    if (HeroVisual.playing && !HeroVisual.paused) {
      HeroVisual.calcOffset(30);

      $('.hero-visual__panel').css({
        transition: 'all 0.5s linear',
        transform: `translate3d(-${HeroVisual.offset}px, 0, 0)`,
      });
      HeroVisual.endAnimation = setTimeout(() => {
        $('.hero-visual__panel').css({
          transition: 'unset',
        });
      }, 500);
    }

    // Clear main loop.
    HeroVisual.playing = false;

    cancelAnimationFrame(HeroVisual.heartbeat);

    HeroVisual.heartbeat = null;
  },

  /**
   * Animation loop.
   */
  mainLoop() {
    HeroVisual.calcOffset(1);

    $('.hero-visual__panel').css({
      transform: `translate3d(-${HeroVisual.offset}px, 0, 0)`,
    });

    // Recursive
    HeroVisual.heartbeat = requestAnimationFrame(HeroVisual.mainLoop);
  },

  /**
   * Calculate offset
   */
  calcOffset(offset) {
    HeroVisual.offset += offset;

    let width = $('.hero-visual-block').width();
    if (HeroVisual.offset > width) {
      HeroVisual.offset = 0;
    }
  },
};

export default HeroVisual;
