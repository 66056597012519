import Form from '@js/partials/form';

const Sage = window.Sage;

const removeItemBtnEl =
  '<button class="remove-item" type="button" aria-label="Remove"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" stroke="black" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 1.5014L10.5 10.5014M1.5 10.5014L10.5 1.5014L1.5 10.5014Z" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/></svg></button>';

const EditObject = {
  address: null,

  init() {
    Form.init();

    this.events();
    this.initRemove();
  },

  events() {
    // Remove message
    $('body').on('click', '.dismiss-comment', (e) => {
      const btnEl = $(e.currentTarget);
      const test = confirm('Are you sure you want to dismiss this?');
      if (test) {
        btnEl.parent().remove();

        EditObject.dismissComment(btnEl.data('id'));
      }
    });

    // Next step
    $('.step-next').on('click', (e) => {
      const btnEl = $(e.currentTarget);
      this.next(btnEl);
      this.handleNav();
    });

    // Prev step
    $('.step-back').on('click', (e) => {
      const btnEl = $(e.currentTarget);
      this.back(btnEl);
      this.handleNav();
    });

    // venti
    if ($('[name=venti]').prop('checked')) {
      $('[name=venti_discount]')
        .removeClass('disabled')
        .parent()
        .removeClass('hidden');
    } else {
      $('[name=venti_discount]')
        .addClass('disabled')
        .parent()
        .addClass('hidden');
    }
    $('[name=venti]').on('change', (e) => {
      const checkbox = $(e.currentTarget);

      if (checkbox.prop('checked')) {
        $('[name=venti_discount]')
          .removeClass('disabled')
          .parent()
          .removeClass('hidden');
      } else {
        $('[name=venti_discount]')
          .addClass('disabled')
          .parent()
          .addClass('hidden');
      }
    });

    // Socials
    $('.add-social__toggle').on('click', (e) => {
      e.preventDefault();
      $('.add-social').toggleClass('add-social--open');

      if ($('.add-social').hasClass('add-social--open')) {
        $('body').addClass('fade-out');
      } else {
        $('body').removeClass('fade-out');
      }
    });
    $(document).on('mouseup', (e) => {
      const addSocial = $('.add-social');

      if (
        addSocial.hasClass('add-social--open') &&
        !addSocial.is(e.target) &&
        addSocial.has(e.target).length === 0
      ) {
        $('body').removeClass('fade-out');
        addSocial.removeClass('add-social--open');
      }
    });
    $('.add-social__option').on('click', (e) => {
      e.preventDefault();
      const btnEl = $(e.currentTarget);
      $('body').removeClass('fade-out');
      $('.add-social').removeClass('add-social--open');

      EditObject.socialAdd(btnEl.data('id'));

      btnEl.remove();

      if (!$('.add-social__option').length) {
        $('.add-social').remove();
      }
    });

    // Clone field
    $('.clone').on('click', (e) => {
      e.preventDefault();
      const btnEl = $(e.currentTarget);

      const target = btnEl.data('target');
      const targetEl = $(`[data-clone=${target}]`).last();
      const index = $(`[data-clone=${target}]`).length;

      const cloned = targetEl.clone();

      cloned.find('input').each((_i, input) => {
        input = $(input);

        let newName = input.attr('name');
        if (newName.includes(`[${index}]`)) {
          newName = newName.replace(`[${index}]`, `[${index + 1}]`);
        }

        input.prop('checked', false);
        if ('images' == target) {
          input.attr('data-type', 'file');
        }

        input.attr('name', newName);
        if (!['checkbox', 'radio'].includes(input.attr('type'))) {
          input.val('').change();
        }
        input.closest('[data-clone]');
      });

      if (target == 'business-hours') {
        cloned.find('.selectize__toggle span').text('-');
      }

      if (target == 'rooms') {
        cloned.find('.form-room__count').text(0);
      }

      cloned.insertAfter(targetEl);

      // Add item remove
      const tempEl = $(`[data-clone=${target}]`);
      EditObject.handleRemoveItem(tempEl);
    });

    // Remove clone item
    $('body').on('click', '.remove-item', (e) => {
      e.preventDefault();

      const btnEl = $(e.currentTarget);

      // Remove current item
      btnEl.parent().remove();

      // Clear other removes
      const target = btnEl.parent().data('clone');
      if ($(`[data-clone=${target}]`).length < 2) {
        $(`[data-clone=${target}] .remove-item`).remove();
      }
    });

    // Image clone
    $('.image-clone').on('click', (e) => {
      e.preventDefault();
      EditObject.imageAdd();
    });

    // Watch object type
    const objectType = $('[name=object-type]:checked').val();
    if (objectType) {
      EditObject.handleObjectType(objectType);
    }
    $('[name=object-type]').on('change', (e) => {
      const input = $(e.currentTarget);

      EditObject.handleObjectType(input.val());
    });

    // Watch object category
    const objectCat = $('[name=category]').val();
    if (objectCat) {
      EditObject.handleObjectCat(objectCat);
    }
    $('[name=category]').on('change', (e) => {
      const input = $(e.currentTarget);
      EditObject.handleObjectCat(input.val());
    });

    // Room field
    $('body').on('click', '.form-room__btn', (e) => {
      const btnEl = $(e.currentTarget);

      this.handleRoomInput(btnEl);
    });
    const roomEl = $('.form-room__input');
    if (roomEl.val()) {
      roomEl
        .siblings('.form-room__label')
        .find('.form-room__count')
        .text(roomEl.val());
    }

    $('body').on('change', '.form-room input', (e) => {
      const input = $(e.currentTarget);
      input.parent().find('.form-room__count').text(input.val());
    });

    // On submit
    $('form.object-edit').on('submit', (e) => {
      e.preventDefault();
      const formEl = $(e.currentTarget);

      formEl.addClass('loading');

      const stepEl = $('.step-box').not('.hidden');

      if (Form.validate(stepEl)) {
        this.submit(formEl);
      } else {
        formEl.removeClass('loading');
      }
    });

    // Image
    $('body').on('change', '[name="images[]"]', (e) => {
      const inputEl = $(e.currentTarget);

      // Check file size
      if (
        inputEl.prop('files').length &&
        inputEl.prop('files')[0].size > 5000000
      ) {
        inputEl.val('');
        inputEl.addClass('error');
        inputEl
          .parent()
          .append(`<p class="form-field__error">${Sage.i18n.file_too_big}</p>`);
        return;
      } else {
        inputEl.removeClass('error');
        inputEl.siblings('.form-field__error').remove();
      }

      // Display file name and remove button
      let name = Sage.i18n.add_image;

      if (inputEl.val()) {
        name = inputEl
          .val()
          .split(/(\\|\/)/g)
          .pop();

        inputEl
          .parent()
          .append(
            '<button class="btn image-remove image-remove--add" type="button" aria-label="Remove image"></button>'
          );
      } else {
        inputEl.siblings('.image-remove').remove();
      }

      inputEl.siblings('.form-field__label').text(name);
    });

    $('body').on('click', '.image-remove--add', (e) => {
      e.preventDefault();
      const btnEl = $(e.currentTarget);
      const test = confirm(Sage.i18n.remove_image_confirm);
      if (test) {
        btnEl.siblings('.form-field__input').val('').change();
      }
    });

    $('body').on('click', '.image-remove--box', (e) => {
      e.preventDefault();
      const btnEl = $(e.currentTarget);
      const test = confirm(Sage.i18n.remove_image_confirm);
      if (test) {
        btnEl.closest('.col').remove();
      }
    });

    // Delete object
    $('.delete-object').on('click', (e) => {
      const test = confirm(Sage.i18n.delete_object_confirm);
      if (!test) {
        e.preventDefault();
      }
    });

    $('.object-nav__item').on('click', (e) => {
      e.preventDefault();
      const btnEl = $(e.currentTarget);

      const targetStep = btnEl.data('step');

      if (targetStep > $('.object-nav__item.active').data('step')) {
        return;
      }

      $('.step-box').addClass('hidden');
      $(`.step-box[data-id=${targetStep}]`).removeClass('hidden');

      this.handleNav();
    });
  },

  initRemove() {
    $('.clone').each((_i, item) => {
      item = $(item);

      const target = item.data('target');
      const tempEl = $(`[data-clone=${target}]`);
      EditObject.handleRemoveItem(tempEl);
    });
  },

  /**
   * Handles room input
   */
  handleRoomInput(btnEl) {
    const input = btnEl.closest('.form-room').find('input');
    const action = btnEl.data('action');

    let val = parseInt(input.val());
    if (!val) {
      val = 0;
    }

    if (action === 'more') {
      val++;
    } else if (action === 'less') {
      val--;
    }

    if (val < 0) {
      val = 0;
    }

    input.val(val).change();
  },

  /**
   * Handles object type
   */
  handleObjectType(type) {
    // Section title
    $('[data-object-text]').each((_i, item) => {
      item = $(item);

      let newText = item
        .attr('data-object-text')
        .replace('{object_type}', Sage.i18n.object_type[type]);

      item.text(newText);
    });

    // Sections
    $('[data-object-type]').each((_i, item) => {
      item = $(item);

      if (!item.data('object-type')) {
        return;
      }

      if (item.data('object-type') == type) {
        item.removeClass('hidden');
        item
          .find('input, select, textarea')
          .removeClass('error')
          .removeClass('disabled');
        item.find('.form-field__error').remove();
      } else {
        item.addClass('hidden');
        item.find('input, select, textarea').addClass('disabled');
      }
    });

    // Object socials
    $('.add-social__option[data-type]').each(function () {
      const s_type = $(this).data('type');
      if (type == s_type) {
        $(this).removeClass('hidden');
      } else {
        $(this).addClass('hidden');
      }
    });

    // $('.social-field[data-id]').each()
  },

  /**
   * Handles object category
   */
  handleObjectCat(category) {
    const target = $('[name=cat-other]').closest('.col');

    if ('other' == category) {
      target.removeClass('hidden');
    } else {
      target.addClass('hidden');
    }
  },

  /**
   *
   */
  handleRemoveItem(wrapEl) {
    if (wrapEl.length > 1) {
      wrapEl.each((_i, item) => {
        item = $(item);
        if (!item.find('.remove-item').length) {
          item.append(removeItemBtnEl);
        }
      });
    } else {
      wrapEl.find('.remove-item').remove();
    }
  },

  /**
   * Go to previous step
   */
  back(btnEl) {
    // show prev
    btnEl
      .closest('.step-box')
      .addClass('hidden')
      .prev('.step-box')
      .removeClass('hidden');
  },

  /**
   * Go to next step
   */
  next(btnEl) {
    const stepEl = btnEl.closest('.step-box');

    // Second to third step
    if (2 == stepEl.data('id')) {
      const addresEl = $('[name=address]');
      if (this.address != addresEl.val()) {
        this.address = addresEl.val();
        EditObject.geocode(this.address);
      }
    }

    // is valid
    if (!Form.validate(stepEl)) {
      $('html, body').animate({ scrollTop: 0 }, 250);
      return;
    }

    // show next
    stepEl.addClass('hidden').next('.step-box').removeClass('hidden');
  },

  handleNav() {
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      300
    );

    const step = $('.step-box:not(.hidden)').data('id');

    $('.object-nav__item').removeClass('active').removeClass('clickable');

    $(`.object-nav__item[data-step="${step}"]`)
      .addClass('active')
      .parent()
      .prevAll()
      .children()
      .addClass('clickable');
  },

  /**
   * Submits form
   */
  submit(formEl) {
    const formData = new FormData(formEl[0]);
    formData.append('action', 'cab_edit_object');

    const imageData = EditObject.getImageData();
    formData.append('image_data', JSON.stringify(imageData));

    $.ajax({
      url: Sage.ajaxurl,
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
    }).done((response) => {
      if (response.success) {
        window.location.href = response.data.url;
      } else {
        formEl.removeClass('loading');
      }
    });
  },

  /**
   * Add social
   */
  socialAdd(id) {
    let prefix = '/';

    if (id === 'tiktok') {
      prefix = '@';
    }

    let icon = '';
    if (Sage.icons[id]) {
      icon = JSON.parse(Sage.icons[id]);
    }

    let html = `<div class="col col-50">
      <label class="social-field" data-id="${id}">
        <div class="social-field__icon">${icon}</div>
        <span>${prefix}</span>
        <input class="social-field__input" type="text" name="${id}" placeholder="${Sage.i18n.your_company}">
      </label>
    </div>`;

    $('[data-id=social]').append(html);
  },

  /**
   * Add image
   */
  imageAdd() {
    const html = `<div class="clone-wrap col col-full" data-clone="images">
      <label class="form-field form-field--file">
        <input class="form-field__input" type="file" name="images[]" accept="image/png, image/jpeg">
        <span class="form-field__label">${Sage.i18n.add_image}</span>
      </label>
    </div>`;

    $('.images').append(html);

    // Add remove
    // const tempEl = $('[data-clone=images]');
    // EditObject.handleRemoveItem(tempEl);
  },

  getImageData() {
    let imageData = [];
    $('.images input').each((_i, item) => {
      item = $(item);

      const type = item.attr('type');

      let index = $(`.images input[type=${type}]`).index(item);

      if (item.val()) {
        imageData.push({
          index,
          type: type,
        });
      }
    });

    return imageData;
  },

  /**
   * Geocode address
   */
  geocode(address) {
    let url = 'https://maps.googleapis.com/maps/api/geocode/json';

    url += '?address=' + encodeURIComponent(address);
    url += '&key=' + Sage.google_api_key;
    // url += '&key=' + 'AIzaSyAVIfEoFfW9idI7T4teR7sZ1jcdGiJ2ygo';

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'OK') {
          const location = data.results[0].geometry.location;
          $('[name=address_coords]').val(JSON.stringify(location));
        }
      });
  },

  dismissComment(index) {
    $.ajax({
      url: Sage.ajaxurl,
      type: 'POST',
      data: {
        action: 'cab_object_dismiss_comment',
        post_id: $('[name=post_id]').val(),
        index,
      },
    });
  },
};

export default EditObject;
