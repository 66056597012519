import Form from '@js/partials/form';

const Sage = window.Sage;

const Account = {
  init() {
    Form.init();
    this.events();
  },

  events() {
    $('.edit-data').on('click', (e) => {
      e.preventDefault();

      $(e.currentTarget).hide();

      $('.user-data--view').hide();
      $('.user-data--edit').show();
    });

    $('.view-data').on('click', (e) => {
      e.preventDefault();

      $('.edit-data').show();

      $('.user-data--view').show();
      $('.user-data--edit').hide();
    });

    $('.user-edit').on('submit', (e) => {
      e.preventDefault();

      const formEl = $(e.currentTarget);

      formEl.addClass('loading');

      if (Form.validate(formEl)) {
        this.submitEdit();
      } else {
        formEl.removeClass('loading');
      }
    });
  },

  submitEdit() {
    const formEl = $('.user-edit');
    const formData = new FormData(formEl[0]);
    formData.append('action', 'cab_user_edit_data');

    $.ajax({
      url: Sage.ajaxurl,
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
    })
      .done((response) => {
        if (response.success) {
          Account.handleUserData();

          formEl.find('[name=password]').val('');

          $('.user-data--view').show();
          $('.user-data--edit').hide();
          $('.edit-data').show();

          // Popup
          $('body').addClass('fade-out');
          $('.popup[data-id=account]').addClass('visible');
        }

        if (!response.success) {
          if (response.data.fields) {
            Form.handleFields(response.data.fields, formEl);
          }
        }
      })
      .always(() => {
        formEl.removeClass('loading');
      });
  },

  handleUserData() {
    $('.account-user-data .form-field__input').each((i, item) => {
      item = $(item);

      $('.user-data-item__value').eq(i).text(item.val());
    });
  },
};

export default Account;
