import { MarkerClusterer } from '@googlemaps/markerclusterer';
import objectItem from '../partials/object-item';

const Sage = window.Sage;

export default {
  infoBubble: null,
  locations: [],
  map: null,

  init() {
    this.events();
    this.addQueryParams();
  },

  events() {
    $('body').on('click', '.add-to-favourites', () => {
      window.location.reload();
    });

    $('.map-btn').on('click', (e) => {
      e.preventDefault();
      const btn = $(e.currentTarget);

      let open = 'true' == btn.attr('data-open');

      let text;

      if (!open) {
        text = Sage.i18n.close_map;
        $('.map-wrap').addClass('col-full').removeClass('col-30');
        $('.content-wrap').addClass('col-full').removeClass('col-70');

        $('.activity-item').parent().removeClass('col-33').addClass('col-25');
      } else {
        text = Sage.i18n.open_map;
        $('.map-wrap').removeClass('col-full').addClass('col-30');
        $('.content-wrap').removeClass('col-full').addClass('col-70');

        $('.activity-item').parent().addClass('col-33').removeClass('col-25');
      }

      open = !open;

      btn.attr('data-open', open);

      btn.children('span').text(text);
    });

    $('.delete-favourites').on('click', () => {
      localStorage.setItem('favourites', JSON.stringify([]));
      location.reload();
    });
  },

  renderMap(markerData) {
    const blocks = Object.keys(markerData);
    blocks.forEach((key) => {
      if (!markerData[key].posts) {
        return;
      }

      markerData[key].posts.forEach((post) => {
        if (!post.location || !post.location.lat || !post.location.lng) {
          return;
        }

        this.locations.push({
          id: post.id,
          num: post.num,
          lat: parseFloat(post.location.lat),
          lng: parseFloat(post.location.lng),
          related: [1],

          data: {
            img: post.img ? post.img.src : null,
            title: post.title,
            url: post.url,
          },
        });
      });
    });

    const mapEl = $('.map');
    const center = {
      lat: 56.949355,
      lng: 24.088514,
    };

    let pos = {
      lat: parseFloat(center.lat),
      lng: parseFloat(center.lng),
    };

    this.infoBubble = new window.google.maps.InfoWindow();

    const pin = mapEl.attr('data-pin');

    this.map = new window.google.maps.Map(mapEl[0], {
      center: pos,
      disableDefaultUI: true,
      zoom: 14,
    });

    const mapBounds = new window.google.maps.LatLngBounds();

    let prevMarker = null;
    const addedMarkers = [];

    this.locations.forEach((locData) => {
      const marker = this.addMarker(locData, this.map, pin);

      mapBounds.extend(marker.getPosition());

      if (prevMarker) {
        this.handleDrawLines(prevMarker, marker);
      }
      addedMarkers.push(marker);
      prevMarker = marker;
    });

    if (addedMarkers.length == 1) {
      const singleMarker = addedMarkers[0];
      this.map.setCenter(singleMarker.getPosition());
    } else {
      this.map.fitBounds(mapBounds);
    }

    this.addClusterer(this.map, addedMarkers);

    /**
     * The custom USGSOverlay object contains the USGS image,
     * the bounds of the image, and a reference to the map.
     */
    const overlayBounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(62.281819, -150.287132),
      new window.google.maps.LatLng(62.400471, -150.005608)
    );

    let image =
      'https://developers.google.com/maps/documentation/javascript/examples/full/images/talkeetna.png';
    class USGSOverlay extends window.google.maps.OverlayView {
      constructor(bounds, image) {
        super();
        this.bounds = bounds;
        this.image = image;
        this.div = null;
      }
      /**
       * onAdd is called when the map's panes are ready and the overlay has been
       * added to the map.
       */
      onAdd() {
        this.div = document.createElement('div');
        this.div.style.borderStyle = 'none';
        this.div.style.borderWidth = '0px';
        this.div.style.position = 'absolute';

        // Create the img element and attach it to the div.
        const img = document.createElement('img');

        img.src = this.image;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        this.div.appendChild(img);

        // Add the element to the "overlayLayer" pane.
        const panes = this.getPanes();

        panes.overlayLayer.appendChild(this.div);
      }
      draw() {
        // We use the south-west and north-east
        // coordinates of the overlay to peg it to the correct position and size.
        // To do this, we need to retrieve the projection from the overlay.
        const overlayProjection = this.getProjection();
        // Retrieve the south-west and north-east coordinates of this overlay
        // in LatLngs and convert them to pixel coordinates.
        // We'll use these coordinates to resize the div.
        const sw = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getSouthWest()
        );
        const ne = overlayProjection.fromLatLngToDivPixel(
          this.bounds.getNorthEast()
        );

        // Resize the image's div to fit the indicated dimensions.
        if (this.div) {
          this.div.style.left = sw.x + 'px';
          this.div.style.top = ne.y + 'px';
          this.div.style.width = ne.x - sw.x + 'px';
          this.div.style.height = sw.y - ne.y + 'px';
        }
      }
      /**
       * The onRemove() method will be called automatically from the API if
       * we ever set the overlay's map property to 'null'.
       */
      onRemove() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
          delete this.div;
        }
      }
      /**
       *  Set the visibility to 'hidden' or 'visible'.
       */
      hide() {
        if (this.div) {
          this.div.style.visibility = 'hidden';
        }
      }
      show() {
        if (this.div) {
          this.div.style.visibility = 'visible';
        }
      }
      toggle() {
        if (this.div) {
          if (this.div.style.visibility === 'hidden') {
            this.show();
          } else {
            this.hide();
          }
        }
      }
      toggleDOM(map) {
        if (this.getMap()) {
          this.setMap(null);
        } else {
          this.setMap(map);
        }
      }
    }
    const overlay = new USGSOverlay(overlayBounds, image);

    overlay.setMap(this.map);

    const toggleButton = document.createElement('button');

    toggleButton.textContent = 'Toggle';
    toggleButton.classList.add('custom-map-control-button');

    const toggleDOMButton = document.createElement('button');

    toggleDOMButton.textContent = 'Toggle DOM Attachment';
    toggleDOMButton.classList.add('custom-map-control-button');
    toggleButton.addEventListener('click', () => {
      overlay.toggle();
    });
    toggleDOMButton.addEventListener('click', () => {
      overlay.toggleDOM(this.map);
    });
    this.map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
      toggleDOMButton
    );
    this.map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
      toggleButton
    );
  },

  addMarker(marker, map, icon) {
    const gmapsMarker = new window.google.maps.Marker({
      position: {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      },
      icon: icon,
      map,
      customData: marker.data,
      id: marker.id,
      label: {
        text: `${marker.num}`,
        color: 'black',
        fontWeight: 'medium',
        fontSize: '1.5rem',
      },
    });

    // Add event listener.
    gmapsMarker.addListener('click', (event) => {
      this.openInfoBubble(event, map, gmapsMarker);
    });

    return gmapsMarker;
  },

  addClusterer(map, markers) {
    const renderer = {
      render: this.renderer,
    };

    new MarkerClusterer({ map, markers, renderer });
  },

  renderer({ position, count }) {
    const text = $('.map').attr('data-cluster');
    // use d3-interpolateRgb to interpolate between red and blue
    // create svg url with fill color
    const svg = window.btoa(
      '<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"><circle cx="22" cy="22" r="22" fill="#feca05"/></svg>'
    );
    // create marker using svg icon
    return new window.google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new window.google.maps.Size(75, 75),
      },
      label: {
        text: String(count + ' ' + text),
        color: '#fff',
        fontSize: '1.5rem',
        fontWeight: '500',
      },
      // adjust zIndex to be above other markers
      zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
    });
  },

  openInfoBubble(event, map, marker) {
    const { img, title, url } = marker.customData;

    const navUrl = `https://www.google.com/maps/dir/?api=1&destination=${event.latLng.lat()},${event.latLng.lng()}`;
    const navIcon =
      '<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8.07071L15.9286 1L8.84429 15.9286L7.28358 9.645L1 8.07071Z" stroke="#0B0E16" stroke-linecap="round" stroke-linejoin="round"/></svg>';
    const moreIcon =
      '<svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.75 0.75L6.5 6L1.25 0.75" stroke="#242731" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/></svg>';
    const infoContent = `
		<div class="info-bubble">
			<div class="info-bubble__content">
				<h3 class="info-bubble__title">${title}</h3>
				<div class="info-bubble__links">
					<a href="${navUrl}" target="_blank" class="info-bubble__link info-bubble__link--nav"><span class="text">${Sage.i18n.navigation_directions}</span> ${navIcon}</a>
					<a href="${url}" target="_blank" class="info-bubble__link info-bubble__link--more"><span class="text">${Sage.i18n.read_more}</span> ${moreIcon}</a>
				</div>
			</div>
			<div class="info-bubble__image">
				<img src="${img}">
			</div>
		</div>`;

    this.infoBubble.close();
    this.infoBubble.setContent(infoContent);
    this.infoBubble.setPosition(event.latLng);
    this.infoBubble.open(map);
  },

  /**
   * Draws lines for item
   */
  handleDrawLines(from, to) {
    const line = this.drawLine(from.getPosition(), to.getPosition());

    line.setMap(this.map);
  },

  /**
   * Draws single line
   */
  drawLine(start, end) {
    // Define a symbol using SVG path notation, with an opacity of 1.
    const lineSymbol = {
      path: 'M -1,1 1,1 1,-1 -1,-1 z',
      fillColor: '#094BF3',
      fillOpacity: 1,
    };

    const lineOptions = {
      path: [start, end],
      clickable: false,
      geodesic: true,
      strokeOpacity: 0,
      icons: [
        {
          icon: lineSymbol,
          offset: '0',
          repeat: '15px',
        },
      ],

      visible: true,
    };

    return new window.google.maps.Polyline(lineOptions);
  },

  addQueryParams() {
    let favouriteItems = JSON.parse(localStorage.getItem('favourites'));
    const params = new URLSearchParams(window.location.search);

    if (params.get('favourites')) {
      $('.loader-wrap').addClass('hidden');
      $('.favorites__empty').removeClass('hidden');
      return;
    }

    if (favouriteItems) {
      const Sage = window.Sage;

      const data = {
        posts: favouriteItems ? favouriteItems.join(',') : null,
        action: 'cab_load_favourites',
        nonce: $('.hidden-nonce').attr('data-nonce'),
      };

      $.ajax({
        type: 'post',
        url: Sage.ajaxurl,
        data: data,
      })
        .done((res) => {
          if (res.success) {
            this.renderMap(res.data);

            $('.favourites-col').html(this.handleBuildHtml(res.data));
            $('.favourites-delete-wrap').removeClass('hidden');

            // Handle loader
            $('.map-wrapper').removeClass('hidden');
            $('.favourites-actions').removeClass('hidden');
          } else {
            $('.favorites__empty').removeClass('hidden');
          }
        })
        .always(() => {
          $('.loader-wrap').addClass('hidden');
        });
    } else {
      $('.loader-wrap').addClass('hidden');
      $('.favorites__empty').removeClass('hidden');
      return;
    }
  },

  handleBuildHtml(data) {
    const blocks = Object.keys(data);

    const endHtml = [];
    blocks.forEach((block) => {
      switch (block) {
        case 'activity':
        case 'restaurant':
        case 'accommodation':
          endHtml.push(this.buildActivityHtml(data[block]));
          break;

        case 'event':
          endHtml.push(this.buildEventsHtml(data[block]));
          break;

        case 'post':
          endHtml.push(this.buildNewsHtml(data[block]));
          break;

        default:
          break;
      }
    });

    return endHtml.join('');
  },

  buildActivityHtml(block) {
    if (!block.posts) {
      return;
    }

    const postsHtml = block.posts
      .map((item) => objectItem.getObjectItemHtml(item, true))
      .join('');

    const contentStr = `
      <div class="favourites-block">
        <h2 class="h2 favourites-block__title">${block.title}</h2>
        <div class="flexbox">
          ${postsHtml}
        </div>
      </div>`;

    return contentStr;
  },

  buildNewsHtml(block) {
    if (!block.posts) {
      return;
    }

    const postsHtml = block.posts
      .map((item) => objectItem.getNewsItemHtml(item, true))
      .join('');

    const contentStr = `
      <div class="favourites-block">
        <h2 class="h2 favourites-block__title">${block.title}</h2>
        ${postsHtml}
      </div>
	  `;

    return contentStr;
  },

  buildEventsHtml(block) {
    if (!block.posts) {
      return;
    }
    const postsHtml = block.posts
      .map((item) => objectItem.getEventItemHtml(item, true))
      .join('');

    const contentStr = `<div class="favourites-block">
        <h2 class="h2 favourites-block__title">${block.title}</h2>
        <div class="flexbox">
          <div class="col col-full">
            <div class="post-list__items">
            ${postsHtml}
            </div>
          </div>
        </div>
      </div>`;

    return contentStr;
  },
};
