import PostFilter from '@js/partials/postFilter';
import activityFilter from '../partials/activityFilter';
import { MarkerClusterer } from '@googlemaps/markerclusterer';

const Sage = window.Sage;

export default {
  map: null,
  mapBounds: null,
  mapMarkers: [],
  mapClusterer: null,
  mapInfoBubble: null,

  init() {
    PostFilter.init();
    activityFilter.init();

    this.events();
    this.toggleMap();
    this.renderMap();
  },

  events() {
    $(
      '.filter-select__input[name=category], .filter-select__input[name=region]'
    ).on('change', () => {
      this.filterMarkers();
    });
  },

  /**
   * Render map
   */
  renderMap() {
    const _self = this;
    const mapEl = $('.aside__map');
    const center = {
      lat: 57.4093575,
      lng: 21.5253897,
    };

    // Create map bounds.
    this.mapBounds = new window.google.maps.LatLngBounds();

    // Create map.
    this.map = new window.google.maps.Map(mapEl[0], {
      center,
      disableDefaultUI: true,
      zoom: 14,
    });

    // Create infowindow.
    this.mapInfoBubble = new window.google.maps.InfoWindow();

    // Get marker data.
    const markedData = JSON.parse(
      mapEl.closest('.aside').find('.markers').text()
    );

    // Create markers.
    markedData.forEach((item) => {
      const marker = this.addMarker(item);
      _self.mapMarkers.push(marker);
      this.mapBounds.extend(marker.getPosition());
    });

    // Fit map to bounds.
    if (this.mapMarkers.length == 1) {
      const marker = this.mapMarkers[0];
      this.map.setCenter(marker.getPosition());
    } else {
      this.map.fitBounds(this.mapBounds);
    }

    // Create marker clusterer.
    this.clusterer = new MarkerClusterer({
      map: this.map,
      markers: this.mapMarkers,
    });
  },

  filterMarkers() {
    const selectCategoryEl = $('.filter-select__input[name=category]');
    const selectRegionEl = $('.filter-select__input[name=region]');

    const filteredCat = Number(selectCategoryEl.val());
    const filteredRegion = Number(selectRegionEl.val());

    this.mapBounds = new window.google.maps.LatLngBounds();

    this.clusterer.clearMarkers();

    let markers = [];

    for (let index = 0; index < this.mapMarkers.length; index++) {
      const marker = this.mapMarkers[index];

      if (
        (!filteredCat || marker.categories.includes(filteredCat)) &&
        (!filteredRegion || marker.regions.includes(filteredRegion))
      ) {
        marker.setVisible(true);

        markers.push(marker);
        this.mapBounds.extend(marker.getPosition());
      } else {
        marker.setVisible(false);
      }
    }

    if (markers.length == 1) {
      const marker = markers[0];
      this.map.setCenter(marker.getPosition());
    } else {
      this.map.fitBounds(this.mapBounds);
    }

    this.clusterer.addMarkers(markers);
  },

  /**
   * Adds marker to map
   */
  addMarker(item) {
    const icon = $('.aside__map').attr('data-pin');

    const marker = new window.google.maps.Marker({
      position: {
        lat: parseFloat(item.lat),
        lng: parseFloat(item.lng),
      },
      categories: item.cat,
      regions: item.region,
      icon,
      map: this.map,
      customData: item.data,
      id: item.id,
    });

    // marker.customData = marker.data;
    // marker.id = marker.id;

    marker.addListener('click', (event) => {
      this.openInfoBubble(event, marker);
    });

    return marker;
  },

  /**
   * Open infowindow on map with context
   */
  openInfoBubble(event, marker) {
    const { img, title, url } = marker.customData;

    const navUrl = `https://www.google.com/maps/dir/?api=1&destination=${event.latLng.lat()},${event.latLng.lng()}`;
    const navIcon =
      '<svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8.07071L15.9286 1L8.84429 15.9286L7.28358 9.645L1 8.07071Z" stroke="#0B0E16" stroke-linecap="round" stroke-linejoin="round"/></svg>';
    const moreIcon =
      '<svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.75 0.75L6.5 6L1.25 0.75" stroke="#242731" stroke-width="1.1" stroke-linecap="round" stroke-linejoin="round"/></svg>';
    const image = img
      ? `<div class="info-bubble__image"><img src="${img}" alt="${title}"></div>`
      : '';
    const bubbleClass = !img ? 'info-bubble--no-img' : '';
    const infoContent = `<div class="info-bubble ${bubbleClass}">
			<div class="info-bubble__content">
				<h3 class="info-bubble__title">${title}</h3>
				<div class="info-bubble__links">
					<a href="${navUrl}" target="_blank" class="info-bubble__link info-bubble__link--nav">
            <span class="text">${Sage.i18n.navigation_directions}</span>
            ${navIcon}
          </a>
					<a href="${url}" target="_blank" class="info-bubble__link info-bubble__link--more">
            <span class="text">${Sage.i18n.read_more}</span>
            ${moreIcon}
          </a>
				</div>
			</div>
      ${image}
		</div>`;

    this.mapInfoBubble.close();
    this.mapInfoBubble.setContent(infoContent);
    this.mapInfoBubble.setPosition(event.latLng);
    this.mapInfoBubble.open(this.map);
  },

  /**
   * Expand map
   */
  toggleMap() {
    let expanded = false;
    const btn = $('.btn-toggle-map');
    const container = btn.closest('.content-container');

    btn.on('click', () => {
      container.toggleClass('content-container--expanded');

      expanded = !expanded;
      if (expanded) {
        btn.find('span').text(btn.attr('data-close'));
      } else {
        btn.find('span').text(btn.attr('data-open'));
      }
    });
  },
};
