const Selectize = {
  init() {
    this.events();
  },

  events() {
    // On selectize toggle
    $('body').on('click', '.selectize__toggle', (e) => {
      const toggle = $(e.currentTarget);
      const wrap = toggle.closest('.selectize');

      $('.selectize')
        .not(wrap)
        .removeClass('selectize--open')
        .data('open', false);

      if (wrap.data('open')) {
        wrap.data('open', false);

        wrap.removeClass('selectize--open');
        $('body').removeClass('fade-out');
      } else {
        wrap.data('open', true);

        wrap.addClass('selectize--open');
        $('body').addClass('fade-out');
      }
    });

    // On option click
    $('body').on('click', '.selectize__option', (e) => {
      const option = $(e.currentTarget);

      const wrap = option.closest('.selectize');

      wrap.children('select').val(option.data('value')).change();
      wrap.removeClass('selectize--open');

      $('body').removeClass('fade-out');
    });

    // On value change
    $('body').on('change', '.selectize__input', (e) => {
      const select = $(e.currentTarget);
      const label = select.children('option:selected').text();
      select.parent().find('.selectize__toggle span').text(label);
    });

    // On click outside
    $(document).on('mouseup', (e) => {
      const selectizeWrap = $('.selectize');

      if (
        selectizeWrap.hasClass('selectize--open') &&
        !selectizeWrap.is(e.target) &&
        selectizeWrap.has(e.target).length === 0
      ) {
        $('body').removeClass('fade-out');
        selectizeWrap.removeClass('selectize--open').data('open', false);
      }
    });

    $('.selectize__input').each((_i, el) => {
      const input = $(el);
      const wrap = input.parent();

      if (input.val()) {
        const label = input.children('option:selected').text();
        wrap.find('.selectize__toggle span').text(label);
      }
    });

    // On days change
    $('body').on('change', '.selectize--days input', (e) => {
      const inputEl = $(e.currentTarget);
      this.daysLabel(inputEl.closest('.selectize'));
    });
    $('.selectize--days').each((_i, el) => {
      this.daysLabel($(el));
    });
  },

  daysLabel(element) {
    let label = '';

    element.find('input:checked').each((_i, el) => {
      const input = $(el);

      label += input.val() + ', ';
    });

    if (label) {
      label += '-';
      label = label.replace(', -', ' -');
      element.find('.selectize__toggle span').text(label);
    }
  },
};

export default Selectize;
