import Router from './util/Router';

// import external dependencies
import 'jquery';

// Import everything from autoload
;

// import local dependencies
import common from './routes/common';
import single from './routes/single';
import templateEvents from './routes/templateEvents';
import templateNews from './routes/templateNews';
import templateObjects from './routes/templateObjects';
import templateVenti from './routes/templateVenti';
import objectTax from './routes/objectTax';
import templateHome from './routes/templateHome';
import favourites from './routes/favourites';
import orderFavourites from './routes/templateOrderFavourites';
import business from './routes/business';
import register from './routes/register';
import account from './routes/account';
import editObject from './routes/edit-object';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Singular
  singlePost: single,
  singleRestaurant: single,
  singleActivity: single,
  singleEvent: single,
  singleAccommodation: single,
  // Events page
  templateFavourites: favourites,
  templateOrderFavourites: orderFavourites,
  // Templates
  templateHome,
  templateEvents,
  templateNews,
  templateRelevant: objectTax,
  templateActivities: templateObjects,
  templateRestaurants: templateObjects,
  templateAccommodations: templateObjects,
  templateBusiness: business,
  templateRegister: register,
  templateAccount: account,
  templateObject: editObject,
  templateVenti,
  // Tax
  taxAccommodationCategory: objectTax,
  taxRestaurantCategory: objectTax,
  taxActivityCategory: objectTax,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
