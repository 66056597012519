import Form from '@js/partials/form';
const Sage = window.Sage;

export default {
  init() {
    Form.init();
    this.events();
  },

  events() {
    const _self = this;

    // On login submit
    $('form.register [type=submit]').on('click', (e) => {
      e.preventDefault();

      const formEl = $('form.register');
      formEl.addClass('loading');

      // Reset
      formEl
        .find('.form__message')
        .addClass('hidden')
        .removeClass('form__message--success form__message--error');

      formEl
        .find('.form-field__input')
        .removeClass('error')
        .siblings('.form-field__error')
        .remove();

      // Validate
      if (!Form.validate(formEl)) {
        formEl.removeClass('loading');
        return;
      }

      // reCAPTCHA
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(Sage.recaptcha_key, { action: 'submit' })
          .then((token) => {
            _self.submitRegister(token);
          });
      });
    });
  },

  submitRegister(token) {
    const formEl = $('form.register');
    const formData = new FormData(formEl[0]);

    formData.append('action', 'cab_user_register');
    formData.append('token', token);

    $.ajax({
      url: Sage.ajaxurl,
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
    })
      .done((response) => {
        if (response.success) {
          formEl.find('.form__message').addClass('form__message--success');
          formEl[0].reset();
        } else {
          formEl.find('.form__message').addClass('form__message--error');

          if (response.data.fields) {
            Form.handleFields(response.data.fields, formEl);
          }
        }

        if (response.data.message) {
          formEl
            .find('.form__message')
            .text(response.data.message)
            .removeClass('hidden');
        }
      })
      .always(() => {
        formEl.removeClass('loading');
      });
  },
};
