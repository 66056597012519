import objectItem from './object-item';

const Sage = window.Sage;

export default {
  init() {
    this.events();
  },

  events() {
    // On filter select.
    let throttle = null;
    $('.post-filters-list .filter-select__input').on('change', () => {
      clearTimeout(throttle);
      throttle = setTimeout(() => {
        this.handleFilter();
      }, 500);
    });

    // On calendar select.
    $('.post-filter-date input').on('change', () => {
      clearTimeout(throttle);
      throttle = setTimeout(() => {
        this.handleFilter();
      }, 500);
    });
  },

  /**
   * Handle filter.
   */
  handleFilter() {
    $('#list-items').html('');

    this.handleLoad();
  },

  /**
   * Create params from filter
   */
  constructParams() {
    const params = {};

    // Add selects.
    $('.post-filters-list .filter-select__input').each((_i, item) => {
      item = $(item);
      if (item.val()) {
        params[item.attr('name')] = item.val();
      }
    });

    // Add date.
    const calendarInputEl = $('.post-filter-date input');
    if (calendarInputEl.val()) {
      params.calendar = calendarInputEl.val();
    }

    return params;
  },

  /**
   * Handle post load
   */
  handleLoad() {
    const params = this.constructParams();

    let url = null;
    if (Object.values(params).length) {
      // Replace url
      url = window.location.pathname + '?' + $.param(params);
    } else {
      url = window.location.pathname;
    }

    history.pushState(params, null, url);

    // Load posts.
    params.action = $('#list-items').data('action');
    params.nonce = $('#list-items').data('nonce');

    $.ajax({
      url: Sage.ajaxurl,
      type: 'POST',
      data: params,
    }).done((response) => {
      // Scroll to news
      $('html, body').animate({
        scrollTop: $('#list-items').offset().top - 350,
      });

      if (response.success) {
        if (response.data.posts && response.data.posts.length) {
          this.renderList(response.data.posts);
        }

        if (response.data.empty || !response.data.posts.length) {
          $('#list-items').html(`<p>${Sage.i18n.no_posts_found}</p>`);
        }
      }
    });
  },

  /**
   * Handle post list
   */
  renderList(items) {
    const postType = $('#list-items').data('post-type');
    let html = '';

    items.forEach((item) => {
      if (postType === 'post') {
        html += objectItem.getNewsItemHtml(item);
      }

      if (postType === 'event') {
        html += objectItem.getEventItemHtml(item);
      }
    });

    // Add to dom;
    $('#list-items').append(html);
  },
};
