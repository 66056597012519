export default {
  objectType: null,
  regionKey: null,
  categoryKey: null,

  init() {
    this.objectType = $('.activity-list').data('type');
    this.regionKey = this.objectType + '_region';
    this.categoryKey = this.objectType + '_cat';

    this.events();
    this.handleOnLoad();
  },

  events() {
    $('.filter-select__input').on('change', () => {
      this.handleFilterChange();
    });
  },

  handleOnLoad() {
    const region = sessionStorage.getItem(this.regionKey);
    const category = sessionStorage.getItem(this.categoryKey);

    if (region) {
      if (region == $('.post-filter-tag').data('id')) {
        $('.post-filter-tag').addClass('active');
        $('input[name=region]').val(region).trigger('change');
      } else {
        setTimeout(() => {
          $(`.filter-select__option[data-id=${region}]`).trigger('click');
        }, 500);
      }
    }

    if (category) {
      if (category == $('.post-filter-tag').data('id')) {
        $('.post-filter-tag').addClass('active');
        $('input[name=category]').val(category).trigger('change');
      } else {
        setTimeout(() => {
          $(`.filter-select__option[data-id=${category}]`).trigger('click');
        }, 500);
      }
    }
  },

  handleFilterChange() {
    const category = Number($('[name=category]').val());
    const region = Number($('[name=region]').val());

    sessionStorage.setItem(this.regionKey, region);
    sessionStorage.setItem(this.categoryKey, category);

    // Reset all elements
    $('[data-cat]').hide();

    $('[data-cat]').each((_i, item) => {
      item = $(item);

      const itemCategories = item.attr('data-cat')
        ? JSON.parse(item.attr('data-cat'))
        : [];
      const regionCategories = item.attr('data-region')
        ? JSON.parse(item.attr('data-region'))
        : [];

      // Show correct elements
      if (region && category) {
        if (
          itemCategories.includes(category) &&
          regionCategories.includes(region)
        ) {
          item.show();
        }
      } else if (region) {
        if (regionCategories.includes(region)) {
          item.show();
        }
      } else if (category) {
        if (itemCategories.includes(category)) {
          item.show();
        }
      } else {
        item.show();
      }
    });
  },
};
