import PostFilter from '@js/partials/postFilter';
import activityFilter from '../partials/activityFilter';

export default {
  init() {
    activityFilter.init();
    PostFilter.init();

    this.regionMap();
  },

  regionMap() {
    const regions = JSON.parse($('.region-map__data').html());

    $('.region-map path').on('mouseenter', (e) => {
      const region = $(e.currentTarget);
      const regionSlug = region.data('id');

      let offsetTop = region.offset().top - $('.region-map').offset().top;
      let offsetLeft = region.offset().left - $('.region-map').offset().left;

      $('.region-map__tooltip').text(regions[regionSlug].name);
      $('.region-map__tooltip').css({
        top: offsetTop,
        left: offsetLeft,
        marginLeft: region[0].getBoundingClientRect().width / 2,
      });

      $('.region-map__tooltip').addClass('visible');
    });

    $('.region-map path').on('click', (e) => {
      const region = $(e.currentTarget);
      const regionSlug = region.data('id');

      $('input[name="region"]')
        .siblings('.filter-select__options')
        .find(`.filter-select__option[data-id=${regions[regionSlug].id}]`)
        .click();

      region.siblings().removeClass('active');
      region.addClass('active');
    });

    $('.region-map').on('mouseleave', () => {
      $('.region-map__tooltip').removeClass('visible');
    });

    $('.post-filters-list__clear').on('click', () => {
      $('.region-map path').removeClass('active');
    });
  },
};
