import 'vanilla-cookieconsent';

const Sage = window.Sage || {};

const CookieNotice = {
  init() {
    if(!Sage.cc.cookie_enabled) {
      return;
    }

    const domain = window.location.hostname.replace('www.', '');

    const necessaryCookies = [
      {
        name: 'cc_cookie',
        domain: domain,
        expiration: '1 ' + Sage.cc.i18n.year,
      },
      {
        name: 'PHPSESSID',
        domain: domain,
        expiration: Sage.cc.i18n.session,
      },
      {
        name: 'wp-wpml_current_language',
        domain: domain,
        expiration: Sage.cc.i18n.session,
      },
    ];

    const preferencesCookies = [
      {
        name: '_icl_visitor_lang_js',
        domain: domain,
        expiration: '1 ' + Sage.cc.i18n.day,
      },
      {
        name: 'wpml_browser_redirect_test',
        domain: domain,
        expiration: Sage.cc.i18n.session,
      },
    ];

    const statisticsCookies = [
      {
        name: '^_ga',
        domain: 'google.com',
        expiration: '2 ' + Sage.cc.i18n.years,
        is_regex: true,
      },
      {
        name: '_gid',
        domain: 'google.com',
        expiration: '1 ' + Sage.cc.i18n.day,
      },
    ];

    const cookieBlocks = [
      {
        title: Sage.cc.i18n.cookie_usage + ' 📢',
        description: Sage.cc.settings.text,
      },
      {
        title: Sage.cc.i18n.necessary,
        description: Sage.cc.settings.necessary,
        toggle: {
          value: 'necessary',
          enabled: true,
          readonly: true,
        },
        cookie_table: necessaryCookies,
      },
      {
        title: Sage.cc.i18n.preferences,
        description: Sage.cc.settings.preferences,
        toggle: {
          value: 'preferences',
          enabled: false,
          readonly: false,
        },
        cookie_table: preferencesCookies,
      },
      {
        title: Sage.cc.i18n.statistics,
        description: Sage.cc.settings.statistics,
        toggle: {
          value: 'statistics',
          enabled: false,
          readonly: false,
        },
        cookie_table: statisticsCookies,
      },
      {
        title: Sage.cc.more.title,
        description: Sage.cc.more.text + Sage.cc.links,
      },
    ];

    var cc = window.initCookieConsent();

    cc.run({
      current_lang: document.documentElement.getAttribute('lang'),
      autoclear_cookies: true,
      cookie_expiration: 365,
      page_scripts: true,
      force_consent: false,

      gui_options: {
        consent_modal: {
          layout: 'box',
          position: 'bottom right',
          transition: 'slide',
        },
        settings_modal: {
          layout: 'box',
          position: 'right',
          transition: 'slide',
        },
      },

      languages: {
        'all': {
          consent_modal: {
            title: Sage.cc.modal.title,
            description: Sage.cc.modal.text + ' <button type="button" data-cc="c-settings" class="cc-link">' + Sage.cc.i18n.let_me_choose + '</button>',
            primary_btn: {
              text: Sage.cc.i18n.accept_all,
              role: 'accept_all',
            },
            secondary_btn: {
              text: Sage.cc.i18n.reject_all,
              role: 'accept_necessary',
            },
          },
          settings_modal: {
            title: Sage.cc.settings.title,
            save_settings_btn: Sage.cc.i18n.save_settings,
            accept_all_btn: Sage.cc.i18n.accept_all,
            reject_all_btn: Sage.cc.i18n.reject_all,
            close_btn_label: Sage.cc.i18n.close,
            cookie_table_headers: [
              {
                name: Sage.cc.i18n.name,
              },
              {
                domain: Sage.cc.i18n.domain,
              },
              {
                expiration: Sage.cc.i18n.expiration,
              },
            ],
            blocks: cookieBlocks,
          },
        },
      },
    });

    // On load delete WPML cookies
    if (!cc.allowedCategory('preferences')) {
      let names = preferencesCookies.map(cookie => {
        return cookie.name;
      });
      cc.eraseCookies(names);
    }
  },
};

export default CookieNotice;
