import LazyLoad from 'vanilla-lazyload';

import lightbox from '@js/partials/lightbox';
import sideShare from '../partials/sideShare';
import cookieNotice from '../partials/cookie-notice';

const Sage = window.Sage;

export default {
  init() {
    lightbox.init();
    sideShare.init();
    cookieNotice.init();

    this.lazyLoad();
    this.events();
    this.scrollToAnchor();
    this.header();
    this.appearance();
    this.langSwitcher();
    this.addToFavourites();
    this.checkFavourites();
    this.popup();
  },

  lazyLoad() {
    new LazyLoad();
  },

  events() {
    $('.copy-url').on('click', () => {
      navigator.clipboard.writeText(document.location.href);
      $('.copy-url span').text(Sage.i18n.copied_link);

      setTimeout(() => {
        $('.copy-url span').text(Sage.i18n.copy_link);
      }, 2000);
    });

    $('.side-nav__link').on('click', (e) => {
      const link = $(e.currentTarget);
      $('.side-nav__link').removeClass('active');
      link.addClass('active');

      const target = link.attr('href');
      $('html, body').animate({
        scrollTop: $(target).offset().top - 200,
      });
    });
  },

  scrollToAnchor() {
    if (!location.hash) {
      return;
    }

    const anchorEl = $(location.hash);

    if (!anchorEl.length) {
      return;
    }

    $('html, body').animate(
      {
        scrollTop: anchorEl.offset().top - 150,
      },
      500
    );
  },

  header() {
    let classTimeout = null;
    let hoverTimeout = null;
    $('.header-desktop .menu-item--megamenu a')
      .on('mouseenter', (e) => {
        clearTimeout(classTimeout);
        clearTimeout(hoverTimeout);

        const target = $(e.currentTarget).data('target');

        $('.header').addClass('header--megamenu');
        $(`.header-megamenu[data-id=${target}]`).addClass(
          'header-megamenu--open'
        );
      })
      .on('mouseleave', () => {
        hoverTimeout = setTimeout(() => {
          $('.header-megamenu').removeClass('header-megamenu--open');
        }, 250);
        classTimeout = setTimeout(() => {
          $('.header').removeClass('header--megamenu');
        }, 550);
      });

    $('.header-megamenu')
      .on('mouseenter', (e) => {
        clearTimeout(classTimeout);
        clearTimeout(hoverTimeout);
        $('.header').addClass('header--megamenu');
        $(e.currentTarget).addClass('header-megamenu--open');
      })
      .on('mouseleave', () => {
        hoverTimeout = setTimeout(() => {
          $('.header-megamenu').removeClass('header-megamenu--open');
        }, 250);
        classTimeout = setTimeout(() => {
          $('.header').removeClass('header--megamenu');
        }, 550);
      });

    $('.toggle-header-menu').on('click', (e) => {
      $(e.currentTarget).toggleClass('active');
      $('.header-mobile-menu').toggleClass('open');
    });

    this.setHeaderClass();
    $(document).scroll(() => {
      this.setHeaderClass();
    });

    $('.toggle-search').on('click', () => {
      $('.search-form').toggleClass('open');
    });
  },

  setHeaderClass() {
    if ($(window).scrollTop() > 50) {
      $('.header').addClass('header--scrolled');
      $('.header-mobile').addClass('header-mobile--scrolled');
    } else {
      $('.header').removeClass('header--scrolled');
      $('.header-mobile').removeClass('header-mobile--scrolled');
    }
  },

  langSwitcher() {
    // Toggle
    $('.lang-switch__toggle').on('click', (e) => {
      $(e.currentTarget).parent().toggleClass('open');
    });

    // Close on escape.
    $(document).keyup(function (e) {
      if (e.key === 'Escape' && $('.lang-switch').hasClass('open')) {
        $('.lang-switch').removeClass('open');
      }
    });

    // CLose on click outside.
    $(document).mouseup(function (e) {
      const langSwitchEl = $('.lang-switch');

      if (
        langSwitchEl.hasClass('open') &&
        !langSwitchEl.is(e.target) &&
        langSwitchEl.has(e.target).length === 0
      ) {
        langSwitchEl.removeClass('open');
      }
    });
  },

  appearance() {
    // Toggle
    $('.appearance__toggle').on('click', (e) => {
      $(e.currentTarget).parent().toggleClass('open');
    });

    // Close on escape.
    $(document).keyup(function (e) {
      if (e.key === 'Escape' && $('.appearance').hasClass('open')) {
        $('.appearance').removeClass('open');
      }
    });

    // CLose on click outside.
    $(document).mouseup(function (e) {
      const appearanceEl = $('.appearance');

      if (
        appearanceEl.hasClass('open') &&
        !appearanceEl.is(e.target) &&
        appearanceEl.has(e.target).length === 0
      ) {
        appearanceEl.removeClass('open');
      }
    });
  },

  checkFavourites() {
    let favourites = localStorage.getItem('favourites');

    if (favourites) {
      favourites = JSON.parse(favourites);

      $('.add-to-favourites').each((_i, btn) => {
        btn = $(btn);

        const prodId = parseInt(btn.attr('data-id'));

        if (favourites.includes(prodId)) {
          btn.addClass('active');
        }
      });
    }
  },

  addToFavourites() {
    $(document).on('click', '.add-to-favourites', (e) => {
      e.preventDefault();

      const btn = $(e.currentTarget);
      const postID = parseInt(btn.attr('data-id'));
      btn.toggleClass('active');

      let favouritePosts = localStorage.getItem('favourites');
      favouritePosts = favouritePosts ? JSON.parse(favouritePosts) : [];

      if (!favouritePosts) {
        localStorage.setItem('favourites', JSON.stringify([postID]));
      } else {
        if (favouritePosts.includes(postID)) {
          favouritePosts = favouritePosts.filter((post) => post != postID);
        } else {
          favouritePosts.push(postID);
        }

        localStorage.setItem('favourites', JSON.stringify(favouritePosts));
      }
    });
  },

  popup() {
    $('.close-popup-js').on('click', (e) => {
      const target = $(e.currentTarget).data('target');

      $(`[data-id=${target}]`).removeClass('visible');
      $('body').removeClass('fade-out');
    });
  },

  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
