export default () => {
  // Set value on construct
  $('.filter-select').each((_i, select) => {
    const selectEl = $(select);
    const input = selectEl.children('.filter-select__input');

    if (input.val() && input.val() != $('.post-filter-tag').data('id')) {
      selectEl.addClass('filter-select--filtered');
      const optionEl = selectEl.find(
        `.filter-select__option[data-id="${input.val()}"]`
      );

      selectEl
        .children('.filter-select__main')
        .find('span')
        .text(optionEl.text());
    }
  });

  // Toggle
  $('.filter-select__main').on('click', (e) => {
    const filterEl = $(e.currentTarget);
    const selectEl = filterEl.parent();
    const siblings = filterEl.closest('.post-filters-list__item').siblings();

    if ($(window).outerWidth() < 600) {
      filterEl.next().slideToggle();
    } else {
      filterEl.next().show();
      // Close siblings
      siblings.find('.filter-select').removeClass('filter-select--open');

      // Toggle el
      if (selectEl.hasClass('filter-select--open')) {
        selectEl.removeClass('filter-select--open');
        $('body').removeClass('fade-out');
      } else {
        selectEl.addClass('filter-select--open');
        $('body').addClass('fade-out');
      }
    }
  });

  // Close on click outside
  $(document).mouseup((e) => {
    const selectEl = $('.filter-select');

    if (
      selectEl.hasClass('filter-select--open') &&
      !selectEl.is(e.target) &&
      selectEl.has(e.target).length === 0
    ) {
      selectEl.removeClass('filter-select--open');
      $('body').removeClass('fade-out');
    }
  });

  // Close on ESC key
  $(document).keyup((e) => {
    if (e.key === 'Escape') {
      const selectEl = $('.filter-select');
      selectEl.removeClass('filter-select--open');
      $('body').removeClass('fade-out');
    }
  });

  // Select option
  $('.filter-select__option').on('click', (e) => {
    const optionEl = $(e.currentTarget);
    const selectEl = optionEl.closest('.filter-select');

    // Change select text
    if ($(window).outerWidth() > 600) {
      selectEl
        .children('.filter-select__main')
        .find('span')
        .text(optionEl.text());
    }

    // Close select
    selectEl.removeClass('filter-select--open');
    $('body').removeClass('fade-out');

    // Change input val
    selectEl
      .children('.filter-select__input')
      .val(optionEl.data('id'))
      .change();
  });

  // On input change.
  $('.filter-select__input').on('change', (e) => {
    const inputEl = $(e.currentTarget);
    const selectEl = inputEl.parent();

    if (inputEl.val() != $('.post-filter-tag').data('id')) {
      if (inputEl.val()) {
        selectEl.addClass('filter-select--filtered');
      } else {
        selectEl.removeClass('filter-select--filtered');
      }
    }
  });

  // On Ventspils click.
  $('.post-filter-tag').on('click', (e) => {
    const ventspilsBtnEl = $(e.currentTarget);

    ventspilsBtnEl.toggleClass('active');
    const target = ventspilsBtnEl.data('id');
    $('input[name=region]').val(target).trigger('change');
  });

  $(window).on('resize', () => {
    if (
      $(window).width() > 600 &&
      $('.post-filters-list').hasClass('post-filters-list--open')
    ) {
      $('body').removeClass('fade-out');
      $('.post-filters-list').removeClass('post-filters-list--open');
    }

    $('.filter-select').each((_i, filterEl) => {
      filterEl = $(filterEl);

      let text = filterEl.children('.filter-select__main').data('label');

      const target = filterEl.children('.filter-select__input').val();
      if ($(window).outerWidth() > 600 && target) {
        text = filterEl
          .find(`.filter-select__option[data-id=${target}]`)
          .text();
      }

      filterEl.children('.filter-select__main').text(text);
    });
  });
};
