import Selectize from '@js/partials/selectize';

const Sage = window.Sage;

export default {
  init() {
    Selectize.init();

    this.events();
  },

  events() {
    $('.form-field__input').each((_i, item) => {
      item = $(item);
      this.fancyInput(item);
    });

    $('body').on('change', '.form-field__input', (e) => {
      const input = $(e.currentTarget);
      this.fancyInput(input);
    });
  },

  fancyInput(input) {
    if (input.val()) {
      input.parent().addClass('form-field--filled');
    } else {
      input.parent().removeClass('form-field--filled');
    }
  },

  /**
   * Check if is valid email
   */
  isValidEmail(email) {
    const re =
      /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  },

  /**
   * Validate form fields
   */
  validate(formEl) {
    let errors = [];
    const inputs = formEl
      .find('input.required, select.required, textarea.required')
      .not('.disabled');

    // Loop over items.
    inputs.each((_i, item) => {
      item = $(item);
      let error = null;

      // Check for type
      switch (item.attr('type')) {
        // email
        case 'email':
          if (!item.val()) {
            error = 'required';
          } else if (!this.isValidEmail(item.val())) {
            error = 'invalid_email';
          }
          break;

        // Radio
        case 'radio':
          if (!$(`input[name="${item.attr('name')}"]:checked`).length) {
            error = 'required';
          }
          break;

        // Checkbox
        case 'checkbox':
          if (!item.is(':checked')) {
            error = 'required';
          }
          break;

        // default
        default:
          if (!item.val()) {
            error = 'required';
          }
      }

      // Handle field
      this.fieldHandleError(item, error);
      if (error) {
        errors.push(error);
      }
    });

    return !errors.length;
  },

  fieldHandleError(input, error) {
    const wrap = input.parent();
    const errorEl = wrap.find('.form-field__error');

    // Add error
    if (error) {
      input.addClass('error');

      let errorMsg = Sage.i18n.form[error] ? Sage.i18n.form[error] : error;

      if (errorEl.length) {
        errorEl.text(errorMsg);
      } else {
        wrap.append(`<p class="form-field__error">${errorMsg}</p>`);
      }
    }

    // Remove error.
    if (!error && errorEl.length) {
      input.removeClass('error');

      if (errorEl.length) {
        errorEl.remove();
      }
    }
  },

  /**
   * Handles form fields
   */
  handleFields(fields, formEl) {
    if (!fields.length) {
      return;
    }

    const _self = this;

    fields.forEach((item) => {
      const inputEl = formEl.find(`[name=${item.name}]`);
      if (inputEl.length) {
        _self.fieldHandleError(inputEl, item.message);
      }
    });
  },
};
