import Swiper from 'swiper';

export default {
  init(galleryEl) {
    let sliderEl = galleryEl.find('.swiper');

    const prevEl = galleryEl.find('.swiper-button--prev');
    const nextEl = galleryEl.find('.swiper-button--next');

    new Swiper(sliderEl[0], {
      spaceBetween: 20,
      autoHeight: true,
      //   modules: [Navigation],
      navigation: {
        prevEl: prevEl[0],
        nextEl: nextEl[0],
      },
    });
  },
};
