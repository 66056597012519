import Form from '@js/partials/form';

const Sage = window.Sage;

export default {
  init() {
    Form.init();
    this.events();
  },

  events() {
    const _self = this;

    // Toggle forms
    $('.show-forgot').on('click', () => {
      $('.login-wrap').addClass('hidden');
      $('.forgot-password-wrap').removeClass('hidden');
    });
    $('.hide-forgot').on('click', () => {
      $('.login-wrap').removeClass('hidden');
      $('.forgot-password-wrap').addClass('hidden');
    });

    // On login submit
    $('form.login [type=submit]').on('click', (e) => {
      e.preventDefault();

      const formEl = $('form.login');

      formEl.addClass('loading');

      // Reset
      formEl.find('.form__message').addClass('hidden');
      formEl
        .find('.form-field__input')
        .removeClass('error')
        .siblings('.form-field__error')
        .remove();

      // Validate
      if (!Form.validate(formEl)) {
        formEl.removeClass('loading');
        return;
      }

      // reCAPTCHA
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(Sage.recaptcha_key, { action: 'submit' })
          .then((token) => {
            _self.submitLogin(token);
          });
      });
    });

    // On password forgot submit
    $('form.forgot-password [type=submit]').on('click', (e) => {
      e.preventDefault();

      const formEl = $('form.forgot-password');

      formEl.addClass('loading');

      // Reset
      formEl.find('.form__message').addClass('hidden');
      formEl
        .find('.form-field__input')
        .removeClass('error')
        .siblings('.form-field__error')
        .remove();

      // Validate
      if (!Form.validate(formEl)) {
        formEl.removeClass('loading');
        return;
      }

      // reCAPTCHA
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(Sage.recaptcha_key, { action: 'submit' })
          .then((token) => {
            _self.submitForgotPassword(token);
          });
      });
    });

    // On password reset submit
    $('form.reset-password').on('submit', (e) => {
      e.preventDefault();

      const formEl = $(e.currentTarget);
      formEl.addClass('loading');

      // Reset
      formEl.find('.form__message').addClass('hidden');
      formEl
        .find('.form-field__input')
        .removeClass('error')
        .siblings('.form-field__error')
        .remove();

      // Validate
      if (!Form.validate(formEl)) {
        formEl.removeClass('loading');
        return;
      }

      // submit
      _self.submitResetPassword();
    });
  },

  /**
   * Submit login
   */
  submitLogin(token) {
    const formEl = $('form.login');
    const formData = new FormData(formEl[0]);

    formData.append('action', 'cab_user_login');
    formData.append('token', token);

    $.ajax({
      url: Sage.ajaxurl,
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
    })
      .done((response) => {
        if (response.data.redirect) {
          window.location.href = response.data.redirect;
        }

        if (!response.success) {
          if (response.data.fields) {
            Form.handleFields(response.data.fields, formEl);
          }
        }

        if (response.data.message) {
          formEl
            .find('.form__message')
            .text(response.data.message)
            .removeClass('hidden');
        } else if (response.data.code) {
          formEl
            .find('.form__message')
            .text(Sage.i18n[response.data.code])
            .removeClass('hidden');
        }
      })
      .always(() => {
        formEl.removeClass('loading');
      });
  },

  /**
   * Submit forgot password
   */
  submitForgotPassword(token) {
    const formEl = $('form.forgot-password');
    const formData = new FormData(formEl[0]);

    formData.append('action', 'cab_user_forgot_password');
    formData.append('token', token);

    $.ajax({
      url: Sage.ajaxurl,
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
    })
      .done((response) => {
        if (response.success) {
          formEl[0].reset();
        } else {
          if (response.data.fields) {
            Form.handleFields(response.data.fields, formEl);
          }
        }

        if (response.data.message) {
          formEl
            .find('.form__message')
            .text(response.data.message)
            .removeClass('hidden');
        }
      })
      .always(() => {
        formEl.removeClass('loading');
      });
  },

  /**
   * Submit reset password
   */
  submitResetPassword() {
    const formEl = $('form.reset-password');
    const formData = new FormData(formEl[0]);

    formData.append('action', 'cab_user_reset_password');

    $.ajax({
      url: Sage.ajaxurl,
      type: 'POST',
      data: formData,
      processData: false,
      contentType: false,
    })
      .done((response) => {
        if (response.success) {
          formEl.hide();
          $('.reset-password-success').removeClass('hidden');
        } else {
          if (response.data.fields) {
            Form.handleFields(response.data.fields, formEl);
          }
        }

        if (response.data.message) {
          $('.form-success-msg').text(response.data.message);

          formEl
            .find('.form__message')
            .text(response.data.message)
            .removeClass('hidden');
        }
      })
      .always(() => {
        formEl.removeClass('loading');
      });
  },
};
