import postGallery from '@js/partials/postGallery';
import Litepicker from 'litepicker/dist/nocss/litepicker.umd.js';
import moment from 'moment';

export default {
  init() {
    const galleryEl = $('.post-gallery');
    if (galleryEl.length) {
      postGallery.init(galleryEl);
    }

    this.renderMap();
    this.calendar();
  },

  renderMap() {
    const mapEl = $('.info-item-map');

    if (!mapEl.length) {
      return;
    }

    let pos = {
      lat: parseFloat(mapEl.data('lat')),
      lng: parseFloat(mapEl.data('lng')),
    };

    const map = new window.google.maps.Map(mapEl[0], {
      center: pos,
      disableDefaultUI: true,
      zoom: 15,
    });
    this.addMarker(pos, map);
  },

  addMarker(pos, map) {
    // eslint-disable-next-line
    const image = Sage.template + '/images/location-pin.svg';

    return new window.google.maps.Marker({
      position: pos,
      map,
      icon: image,
      animation: window.google.maps.Animation.DROP,
      clickable: false,
    });
  },

  calendar() {
    const calendarEl = $('.post-calendar__box');
    const picker = new Litepicker({
      element: calendarEl[0],
      inlineMode: true,
      singleMode: false,
      lang: $('html').attr('lang'),
      buttonText: {
        previousMonth:
          '<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.80157 1.5L1.60156 6.70001L6.80157 11.9" stroke="#F6F7FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
        nextMonth:
          '<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 12L6.70007 6.79999L1.5 1.60001" stroke="#F6F7FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      },
    });

    this.fixCalendar();
    picker.on('render', () => {
      this.fixCalendar();
    });

    picker.on('selected', (date1, date2) => {
      const dates = [
        moment(date1.dateInstance).format('YYYY/MM/DD'),
        moment(date2.dateInstance).format('YYYY/MM/DD'),
      ];
      $('.post-calendar-js').val(dates.join('-'));
    });
  },

  fixCalendar() {
    const calendarEl = $('.post-calendar__box');

    // Fix days.
    const days = calendarEl.find('.container__days div');
    days.each((_i, day) => {
      day = $(day);
      day.html('<span>' + day.text() + '</span>');
    });

    let weekdaysTranslation = $('.post-calendar-js').data('weekdays');

    // Fix weekdays.
    const weekdayContainers = calendarEl.find('.month-item-weekdays-row');
    weekdayContainers.each((i, weekdayContainer) => {
      const weekdays = $(weekdayContainer).children('div');
      weekdays.each((i, weekday) => {
        weekday = $(weekday);
        let text = weekdaysTranslation[i];
        weekday.text(text);
      });
    });
  },
};
