import Swiper, { Navigation } from 'swiper';
import HeroVisual from '@js/partials/hero-visual';

export default {
  init() {
    HeroVisual.init();

    this.events();
    this.newsSlider();
    this.eventsSlider();
    this.navBlock();
    this.feeds();
    this.team();
  },

  events() {
    // close tooltips on body click
    $(document).mouseup((e) => {
      var container = $('.team-contact__item');

      // if the target of the click isn't the container nor a descendant of the container
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.find('.team-contact__tooltip').removeClass('active');
      }
    });
  },

  newsSlider() {
    const sliderEl = $('.news-slider-wrap');
    const slider = new Swiper(sliderEl.find('.news-slider')[0], {
      modules: [Navigation],
      slidesPerView: 4,
      spaceBetween: 25,
      autoHeight: true,
      navigation: {
        nextEl: sliderEl.find('.swiper-next')[0],
        prevEl: sliderEl.find('.swiper-prev')[0],
      },
      lazyLoad: {
        loadPrevNext: true,
        loadPrevNextAmount: 4,
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        600: {
          slidesPerView: 3,
        },
        900: {
          slidesPerView: 4,
        },
      },
      on: {
        init() {
          sliderEl
            .find('.page--last')
            .text(sliderEl.find('.swiper-slide').length);
        },
      },
    });

    slider.on('slideChange', function () {
      sliderEl.find('.page--current').text(slider.activeIndex + 1);
    });

    this.sliderNav(sliderEl);
    $(window).on('resize', this.sliderNav(sliderEl));
  },

  eventsSlider() {
    const sliderEl = $('.events-slider-wrap');
    const slider = new Swiper(sliderEl.find('.events-slider')[0], {
      modules: [Navigation],
      slidesPerView: 4,
      spaceBetween: 25,
      autoHeight: true,
      navigation: {
        nextEl: sliderEl.find('.swiper-next')[0],
        prevEl: sliderEl.find('.swiper-prev')[0],
      },
      lazyLoad: {
        loadPrevNext: true,
        loadPrevNextAmount: 4,
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 3,
        },
        900: {
          slidesPerView: 4,
        },
      },
      on: {
        init() {
          sliderEl
            .find('.page--last')
            .text(sliderEl.find('.swiper-slide').length);
        },
      },
    });

    slider.on('slideChange', function () {
      sliderEl.find('.page--current').text(slider.activeIndex + 1);
    });

    this.sliderNav(sliderEl);
    $(window).on('resize', this.sliderNav(sliderEl));
  },

  sliderNav(sliderEl) {
    let max = 5;
    if ($(window).outerWidth() < 900) {
      max = 4;
    }

    if (sliderEl.find('.swiper-slide').length < max) {
      sliderEl.find('.swiper-nav').hide();
    } else {
      sliderEl.find('.swiper-nav').show();
    }
  },

  navBlock() {
    $('.nav-block__item').on('mouseenter', (e) => {
      const link = $(e.currentTarget);

      // Remove active classes
      $('.nav-block__item').removeClass('nav-block__item--active');
      $('.nav-block__bg').removeClass('nav-block__bg--active');

      // Add active class to selected elements
      link.addClass('nav-block__item--active');
      $(`.nav-block__bg[data-id="${link.attr('data-target')}"]`).addClass(
        'nav-block__bg--active'
      );
    });

    $('.nav-block__item').on('click', (e) => {
      const link = $(e.currentTarget);

      if (!link.hasClass('nav-block__item--active')) {
        e.preventDefault();
        $('.nav-block__item').removeClass('nav-block__item--active');
        $('.nav-block__bg').removeClass('nav-block__bg--active');

        link.addClass('nav-block__item--active');
        $(`.nav-block__bg[data-id="${link.attr('data-target')}"]`).addClass(
          'nav-block__bg--active'
        );
      }
    });
  },

  feeds() {
    $('.feeds-header__logo').on('click', (e) => {
      const btn = $(e.currentTarget);

      btn.addClass('active').siblings().removeClass('active');

      $(`.feeds-list[data-id="${btn.data('target')}"]`)
        .removeClass('hidden')
        .siblings()
        .addClass('hidden');
    });
  },

  team() {
    $('.team-contact__item-toggle').on('click', (e) => {
      const btn = $(e.currentTarget);

      const parentEl = btn.closest('.team-contact__item');

      parentEl.siblings().find('.team-contact__tooltip').removeClass('active');

      parentEl.find('.team-contact__tooltip').toggleClass('active');
    });

    $('.copy-tooltip').on('click', (e) => {
      // Close open tooltips
      $('.team-contact__tooltip').removeClass('active');

      const btn = $(e.currentTarget);
      const btnParent = btn.closest('.team-contact__tooltip');

      this.copyToClipboard(btnParent.find('.team-contact__tooltip-text'));

      btnParent.find('.team-contact__tooltip-copied').addClass('visible');

      setTimeout(() => {
        btnParent.find('.team-contact__tooltip-copied').removeClass('visible');
      }, 1000);
    });
  },

  copyToClipboard(element) {
    var $temp = $('<input>');
    $('body').append($temp);
    $temp.val($(element).text()).select();
    document.execCommand('copy');
    $temp.remove();
  },
};
