const Sage = window.Sage;

export default {
  getObjectItemHtml(item, favorite = false) {
    let itemHtml = '';
    let active = '';

    if (favorite === true) {
      active = 'active';
    }

    // Add image.
    let imageHtml = '';
    if (item.img) {
      imageHtml = `<img src="${item.img.src}" srcset="${item.img.srcset}" width="${item.img.width}" height="${item.img.height}" alt="${item.img.alt}">`;
    }

    // Num html.
    let numHtml = '';
    if (item.num) {
      numHtml = `<span class="activity-item__num">${item.num}</span>`;
    }

    // Venti html
    let ventiHtml = '';
    if (item.venti) {
      ventiHtml = `<span class="activity-item__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="18.611" height="16.167" viewBox="0 0 18.611 16.167"><path d="M8.591,15.654l-1.866-5.6H3.2a.75.75,0,0,1,0-1.5h3.03L5.5,6.389H.75a.75.75,0,0,1,0-1.5H5L3.7.987A.75.75,0,0,1,5.126.513L6.584,4.889h5.438L13.48.513A.75.75,0,1,1,14.9.987l-1.3,3.9h4.259a.75.75,0,0,1,0,1.5H13.1L12.38,8.555h3.037a.75.75,0,0,1,0,1.5H11.88l-1.866,5.6a.75.75,0,0,1-1.423,0ZM9.3,13.046l1-2.99H8.307Zm1.5-4.49.722-2.166H7.084l.722,2.166Z" transform="translate(0 0)" fill="#17110d"></path></svg>
      </span>`;
    }

    itemHtml = `<div class="col col-33 col-m-33 col-s-full">
      <article class="activity-item activity-item--single">
        <button class="btn add-to-favourites ${active}" data-id="${item.favourite_id}">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13.023" viewBox="0 0 15 13.023"><g transform="translate(187.25 -386.75)"><path d="M4.625,0A4.125,4.125,0,0,1,7.542,1.208L8,1.666l.458-.458a4.125,4.125,0,0,1,5.834,5.834L8.53,12.8a.75.75,0,0,1-1.061,0L1.708,7.042A4.125,4.125,0,0,1,4.625,0ZM8,3.477a.748.748,0,0,1-.53-.22l-.988-.988A2.625,2.625,0,1,0,2.769,5.981L8,11.212l5.231-5.231A2.625,2.625,0,1,0,9.519,2.269l-.988.988A.748.748,0,0,1,8,3.477Z" transform="translate(-187.75 386.75)"></path><path d="M4.625,0A4.125,4.125,0,0,1,7.542,1.208L8,1.666l.458-.458a4.125,4.125,0,0,1,5.834,5.834L8.53,12.8a.75.75,0,0,1-1.061,0L1.708,7.042A4.125,4.125,0,0,1,4.625,0Z" transform="translate(-187.75 386.75)"></path></g></svg>
        </button>
        <a class="activity-item__link" href="${item.url}">
          <div class="activity-item__bg">
            ${imageHtml}
          </div>

          <div class="activity-item__overlay"></div>

          <div class="activity-item__title-wrap">
            ${numHtml}
            <h4 class="activity-item__title">${item.title}</h4>
            ${ventiHtml}
          </div>
        </a>
      </article>
    </div>`;

    return itemHtml;
  },

  getNewsItemHtml(item, favorite = false) {
    let itemHtml = '';
    let active = '';

    if (favorite === true) {
      active = 'active';
    }

    let imageHtml = '';
    if (item.img) {
      imageHtml = `<img src="${item.img.src}" width="270" height="190" alt="${item.img.alt}">`;
    }

    // Num html.
    let numHtml = '';
    if (item.num) {
      numHtml = `<span class="news-item__num">${item.num}</span>`;
    }

    itemHtml = `<article class="news-item">
      <div class="news-item__image">${imageHtml}</div>
      <div class="news-item__favorite">
        <button class="btn add-to-favourites ${active}" data-id="${item.favourite_id}">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13.023" viewBox="0 0 15 13.023"><g transform="translate(187.25 -386.75)"><path d="M4.625,0A4.125,4.125,0,0,1,7.542,1.208L8,1.666l.458-.458a4.125,4.125,0,0,1,5.834,5.834L8.53,12.8a.75.75,0,0,1-1.061,0L1.708,7.042A4.125,4.125,0,0,1,4.625,0ZM8,3.477a.748.748,0,0,1-.53-.22l-.988-.988A2.625,2.625,0,1,0,2.769,5.981L8,11.212l5.231-5.231A2.625,2.625,0,1,0,9.519,2.269l-.988.988A.748.748,0,0,1,8,3.477Z" transform="translate(-187.75 386.75)"></path><path d="M4.625,0A4.125,4.125,0,0,1,7.542,1.208L8,1.666l.458-.458a4.125,4.125,0,0,1,5.834,5.834L8.53,12.8a.75.75,0,0,1-1.061,0L1.708,7.042A4.125,4.125,0,0,1,4.625,0Z" transform="translate(-187.75 386.75)"></path></g></svg>
        </button>
      </div>
      <div class="news-item__view">
        <a class="btn btn-solid btn-solid--large btn-icon btn-icon--right"
          href="${item.url}">
          <span>${Sage.i18n.read}</span>
          <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.75 6H1.25M9.5 0.75L14.75 6L9.5 0.75ZM14.75 6L9.5 11.25L14.75 6Z" stroke="#17110D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        </a>
      </div>
      <div class="news-item__content">
        <h3 class="h4 news-item__title">${numHtml}<a href="${item.url}">${item.title}</a></h3>
        <p class="news-item__desc">${item.desc}</p>
      </div>
		</article>`;

    return itemHtml;
  },

  getEventItemHtml(item, favorite = false) {
    let itemHtml = '';
    let active = '';

    if (favorite === true) {
      active = 'active';
    }

    // Add image maybe
    let imageHtml = '';
    if (item.img) {
      imageHtml = `<img src="${item.img.src}" alt="${item.img.alt}" width="300" height="200" />`;
    }

    // Add status maybe
    if (['canceled', 'sold-out', 'transferred'].includes(item.status.id)) {
      imageHtml += `<div class="list-item__image-ovelay">
        <p class="list-item__image-ovelay-text">${item.status.label}</p>
      </div>`;
    }

    // Add to date maybe
    let dateToHtml = '';
    if (!item.date.same_day) {
      dateToHtml = `<p>līdz</p>
			<p class="list-item__date-day">${item.date.to.day}</p>
			<p class="list-item__date-month">${item.date.to.month}</p>`;
    }

    // Add time maybe
    let dateTime = '';
    if (item.date.same_day && item.date.time) {
      dateTime = `<div class="list-item__date-bottom">
			<p class="list-item__date-time">${item.date.time}</p>
		  </div>`;
    }

    let statusHtml = '';
    if ('available' == item.status.id) {
      if (item.ticket_link) {
        statusHtml += `<a class="btn btn-icon list-item__status-label list-item__status-label--yellow" href="${item.ticket_link}"
          target="_blank" rel="nofollow">
          <span class="text"> ${Sage.i18n.buy_ticket}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="#C4B5B2" viewBox="0 0 9 9"><path d="M4.146,8.853a.5.5,0,0,1,0-.707L7.293,5H.5a.5.5,0,1,1,0-1H7.293L4.146.853A.5.5,0,0,1,4.854.147L8.845,4.138a.5.5,0,0,1,0,.725L4.854,8.853a.5.5,0,0,1-.707,0Z" transform="translate(0 0)"/></svg>
        </a>`;
      }
    } else {
      statusHtml += `<a class="btn list-item__status-label list-item__status-label--${item.status.color}" href="${item.url}">${item.status.label}</a>`;
    }

    // Checks status and adds article tag class
    let statusClass = '';
    if (item.status.id !== 'available' && item.status.id !== 'free') {
      statusClass = 'list-item--unaviable';
    }

    // Num html.
    let numHtml = '';
    if (item.num) {
      numHtml = `<span class="list-item__num">${item.num}</span>`;
    }

    let locationHtml = '';
    if (item.location && typeof item.location != 'object') {
      locationHtml = `<p class="block-desc list-item__address">${item.location}</p>`;
    }

    itemHtml += `<article class="list-item ${statusClass}">

		  <div class="list-item__date">
        <div class="list-item__date-main">
          <p class="list-item__date-day">${item.date.from.day}</p>
          <p class="list-item__date-month">${item.date.from.month}</p>
          ${dateToHtml}
        </div>
        ${dateTime}
		  </div>

		  <div class="list-item__favorite">
        <button class="btn add-to-favourites ${active}" data-id="${item.favourite_id}">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13.023" viewBox="0 0 15 13.023"><g transform="translate(187.25 -386.75)"><path d="M4.625,0A4.125,4.125,0,0,1,7.542,1.208L8,1.666l.458-.458a4.125,4.125,0,0,1,5.834,5.834L8.53,12.8a.75.75,0,0,1-1.061,0L1.708,7.042A4.125,4.125,0,0,1,4.625,0ZM8,3.477a.748.748,0,0,1-.53-.22l-.988-.988A2.625,2.625,0,1,0,2.769,5.981L8,11.212l5.231-5.231A2.625,2.625,0,1,0,9.519,2.269l-.988.988A.748.748,0,0,1,8,3.477Z" transform="translate(-187.75 386.75)"></path><path d="M4.625,0A4.125,4.125,0,0,1,7.542,1.208L8,1.666l.458-.458a4.125,4.125,0,0,1,5.834,5.834L8.53,12.8a.75.75,0,0,1-1.061,0L1.708,7.042A4.125,4.125,0,0,1,4.625,0Z" transform="translate(-187.75 386.75)"></path></g></svg>
        </button>
		  </div>

      <a href="${item.url}" class="list-item__inner">
        <div class="list-item__image">
			    ${imageHtml}
		    </div>

        <div class="list-item__main">
          <div class="list-item__content">
            ${locationHtml}
            <h3 class="h4 list-item__title">${numHtml + item.title}</h3>
            <p class="block-desc list-item__desc">${item.desc}</p>
          </div>
        </div>
      </a>

      <div class="list-item__status">
        ${statusHtml}
      </div>
	  </article>`;

    return itemHtml;
  },
};
