import PostFilter from '@js/partials/postFilter';
import PostFilterLoad from '@js/partials/postFilterLoad';
import ObjectItem from '../partials/object-item';

const Sage = window.Sage;

export default {
  loadingMore: false,

  init() {
    PostFilter.init();
    PostFilterLoad.init();

    this.events();
  },

  events() {
    $('.load-more-news').on('click', () => {
      this.loadMore();
    });
  },

  loadMore() {
    if (this.loadingMore) {
      return;
    }

    $('.load-more-news').text(Sage.i18n.loading);

    this.loadingMore = true;
    const params = PostFilterLoad.constructParams();
    let page = Number($('#list-items').attr('data-page'));

    page++;

    $('#list-items').attr('data-page', page);

    params.action = 'cab_load_more_news';
    params.page = page;
    params.nonce = $('#list-items').data('nonce');

    $.ajax({
      url: Sage.ajaxurl,
      type: 'POST',
      data: params,
    })
      .then((response) => {
        if (response.success) {
          let html = '';
          response.data.posts.forEach((item) => {
            html += ObjectItem.getNewsItemHtml(item);
          });

          $('#list-items').append(html);
        }

        if (response.data.empty) {
          $('.load-more-news').remove();
        }
      })
      .always(() => {
        $('.load-more-news').text(Sage.i18n.load_more);
        this.loadingMore = false;
      });
  },
};
