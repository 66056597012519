import Form from '@js/partials/form';
import objectItem from '../partials/object-item';

const Sage = window.Sage;

export default {
  spentItemsLoading: false,

  init() {
    Form.init();
    this.events();
  },

  events() {
    $('.venti-section__title').on('click', (e) => {
      if ($(window).width() > 900) {
        return;
      }

      const wrapEl = $(e.currentTarget).parent();
      // Remove from other
      $('.venti-section')
        .not(wrapEl)
        .removeClass('open')
        .children('.venti-section__content')
        .slideUp(250);

      // Add to current
      wrapEl
        .addClass('open')
        .children('.venti-section__content')
        .slideDown(250);

      // Animate.
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        250
      );
    });

    // User settings toggle
    $('.toggle-settings').on('click', () => {
      $('.user-settings').toggleClass('hidden');
    });

    // Login modal toggle
    $('.toggle-login').on('click', () => {
      $('.login').toggleClass('hidden');
    });

    $('.load-more').on('click', () => {
      this.loadMoreSpentItems();
    });
  },

  loadMoreSpentItems() {
    if (this.spentItemsLoading) {
      return;
    }
    this.spentItemsLoading = true;
    const wrapperEl = $('.spend-items');

    let page = Number(wrapperEl.attr('data-page'));
    const maxPage = Number(wrapperEl.data('max'));

    page++;

    wrapperEl.attr('data-page', page);

    if (page >= maxPage) {
      $('.load-more').parent().remove();
    }

    $.ajax({
      url: Sage.ajaxurl,
      type: 'POST',
      data: {
        action: 'cab_load_more_spend_items',
        page,
      },
    }).done((response) => {
      this.spentItemsLoading = false;

      if (response.data.posts) {
        wrapperEl.append(this.buildSpentItems(response.data.posts));
      }
    });
  },

  buildSpentItems(items) {
    let html = '';
    items.forEach((item) => {
      html += objectItem.getObjectItemHtml(item);
    });
    return html;
  },
};
