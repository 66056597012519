import Litepicker from 'litepicker/dist/nocss/litepicker.umd.js';
import moment from 'moment';

export default () => {
  const datePickerEl = $('.post-filter-date');
  if (!datePickerEl.length) {
    return;
  }

  // If has input, set dates.
  const inputEl = datePickerEl.children('input');

  let startDate = null,
    endDate = null;
  if (inputEl.val()) {
    let date = inputEl.val().split('-');
    startDate = date[0];
    endDate = date[1];

    let date1 = moment(startDate).format('DD.MM.YYYY');
    let date2 = moment(endDate).format('DD.MM.YYYY');
    datePickerEl.find('.post-filter-date__input--start span').text(date1);
    datePickerEl.find('.post-filter-date__input--end span').text(date2);
    datePickerEl.find('.post-filter-date__clear').removeClass('hidden');
  }

  // Create picker.
  const picker = new Litepicker({
    element: datePickerEl.children('.post-filter-date__picker')[0],
    format: 'YYYY/MM/DD',
    startDate,
    endDate,
    inlineMode: true,
    singleMode: false,
    lang: document.getElementsByTagName('html')[0].getAttribute('lang'),
    buttonText: {
      previousMonth:
        '<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.80157 1.5L1.60156 6.70001L6.80157 11.9" stroke="#F6F7FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      nextMonth:
        '<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 12L6.70007 6.79999L1.5 1.60001" stroke="#F6F7FB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
    },
  });

  // Add class.
  datePickerEl.find('.litepicker').addClass('litepicker--box');

  // On render
  picker.on('render', () => {
    datePickerEl.find('.litepicker').addClass('litepicker--box');

    // Fix days.
    const days = datePickerEl.find('.container__days div');
    days.each((_i, day) => {
      day = $(day);
      day.html('<span>' + day.text() + '</span>');
    });

    // Fix weekdays.
    const weekdayContainers = datePickerEl.find('.month-item-weekdays-row');
    weekdayContainers.each((i, weekdayContainer) => {
      const weekdays = $(weekdayContainer).children('div');
      weekdays.each((i, weekday) => {
        weekday = $(weekday);
        let text;
        if (i < 6) {
          text = weekday.text().substring(0, 1);
        }
        if (i == 6) {
          text = weekday.text().substring(0, 2);
        }
        weekday.text(text);
      });
    });
  });

  // Date picked
  picker.on('selected', (date1, date2) => {
    $('body').removeClass('fade-out');

    if (!date1 || !date2) {
      return;
    }

    // Set input date.
    const dates = [
      moment(date1.dateInstance).format('YYYY/MM/DD'),
      moment(date2.dateInstance).format('YYYY/MM/DD'),
    ];

    datePickerEl.children('input').val(dates.join('-')).trigger('change');

    // Set placeholder date.
    date1 = moment(date1.dateInstance).format('DD.MM.YYYY');
    date2 = moment(date2.dateInstance).format('DD.MM.YYYY');

    datePickerEl.children('.post-filter-date__picker').removeClass('open');
    datePickerEl.find('.post-filter-date__input--start span').text(date1);
    datePickerEl.find('.post-filter-date__input--end span').text(date2);
    datePickerEl.find('.post-filter-date__clear').removeClass('hidden');
  });

  // Close on click outside
  $(document).mouseup((e) => {
    const pickerEl = $('.post-filter-date__picker');

    if (
      pickerEl.hasClass('open') &&
      !pickerEl.is(e.target) &&
      pickerEl.has(e.target).length === 0
    ) {
      pickerEl.removeClass('open');
      $('body').removeClass('fade-out');
    }
  });

  // On input click
  $('.post-filter-date__input').on('click', () => {
    // Show.
    $('body').addClass('fade-out');
    datePickerEl.children('.post-filter-date__picker').addClass('open');
  });

  // Clear
  $('.post-filter-date__clear').on('click', (e) => {
    picker.clearSelection();
    $(e.currentTarget).addClass('hidden');
    datePickerEl.children('input').val('').trigger('change');
    datePickerEl.find('.post-filter-date__input--start span').html('&nbsp;');
    datePickerEl.find('.post-filter-date__input--end span').html('&nbsp;');
  });
};
